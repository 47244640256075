// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-step-wrapper {
    box-sizing: border-box;
    padding: 68px 285px;
    display: flex;
    flex-direction: column;
}

.top-side-text {
    margin-left: 23.5%;
}

.description-area {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.description-textarea {
    box-sizing: border-box;
    align-self: center;
    width: 100%;
    max-width: 714px;
    max-height: 317px;
    aspect-ratio: 16 / 9 ;
    border: 1px solid #C8CBD6;
    border-radius: 8px;
    padding: 22px 28px;
    color: #232940;
    font-family: 'Ubuntu Regular', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/DescriptionStep/description_step.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,yCAAyC;AAC7C","sourcesContent":[".description-step-wrapper {\n    box-sizing: border-box;\n    padding: 68px 285px;\n    display: flex;\n    flex-direction: column;\n}\n\n.top-side-text {\n    margin-left: 23.5%;\n}\n\n.description-area {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-top: 32px;\n}\n\n.description-textarea {\n    box-sizing: border-box;\n    align-self: center;\n    width: 100%;\n    max-width: 714px;\n    max-height: 317px;\n    aspect-ratio: 16 / 9 ;\n    border: 1px solid #C8CBD6;\n    border-radius: 8px;\n    padding: 22px 28px;\n    color: #232940;\n    font-family: 'Ubuntu Regular', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
