// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-method-section {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .option.selected {
    border-color: #008489; /* albastru Airbnb */
  }
  
  .payment-method-radio-button {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  
  .option.selected .payment-method-radio-button {
    background-color: #008489; /* albastru Airbnb */
  }
  `, "",{"version":3,"sources":["webpack://./src/checkout/components/payment_methods/payment_method.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB,EAAE,oBAAoB;EAC7C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB,EAAE,oBAAoB;EACjD","sourcesContent":[".payment-method-section {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .option {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .option.selected {\n    border-color: #008489; /* albastru Airbnb */\n  }\n  \n  .payment-method-radio-button {\n    width: 20px;\n    height: 20px;\n    margin-right: 10px;\n    border-radius: 50%;\n    border: 1px solid #ccc;\n  }\n  \n  .option.selected .payment-method-radio-button {\n    background-color: #008489; /* albastru Airbnb */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
