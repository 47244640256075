import React, { useContext, useEffect } from "react";
import "./description_step.css";
import { StepperContext } from "../stepper/stepper_context";

const DescriptionStep = () => {
  const { setDroneData, droneData, saveDroneChanges } =
    useContext(StepperContext)!;

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (droneData) {
      const newDescription = e.target.value;
      const updatedDroneData = {
        ...droneData,
        description: newDescription,
      };
      setDroneData(updatedDroneData);
    }
  };

  if (!droneData) {
    return <div>Context is not available</div>;
  }

  return (
    <div className="description-step-wrapper">
      <div className="top-side-text">
        <h2>What makes you suitable for the job?</h2>
        <p>
          More details, about your description - higher chance to be discovered
        </p>
      </div>
      <div className="description-area">
        <textarea
          className="description-textarea"
          placeholder="Describe your description here..."
          maxLength={500}
          value={droneData.description || ""}
          onChange={handleDescriptionChange}
        />
      </div>
    </div>
  );
};

export default DescriptionStep;
