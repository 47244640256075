// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-step-wrapper {
    box-sizing: border-box;
    padding: 68px 281px 0 285px;
}

`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/LocationStep/location_step.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".location-step-wrapper {\n    box-sizing: border-box;\n    padding: 68px 281px 0 285px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
