import React, { useState, useEffect } from "react";
import ModalOverlay from "./ModalOverlay/ModalOverlay";
import LoginForm from "./LoginForm/LoginForm";
import CloseButton from "./CloseButton/CloseButton";
import "./login.css";

interface LoginProps {
  isVisible: boolean;
  onClose: () => void;
}

const Login: React.FC<LoginProps> = ({ isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset overflow property when component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  return isVisible ? (
    <ModalOverlay>
      <div className="login-modal-content">
        <CloseButton onClick={onClose} />
        <h2>Login or Signup</h2>
        <hr />
        <p className="join-us-txt">
          Join us and take your adventures to new heights!
        </p>
        <LoginForm />
      </div>
    </ModalOverlay>
  ) : null;
};

export default Login;
