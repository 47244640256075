// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=email], input[type=password] {
    font-size: 0.875em;
    padding: 12px 0 12px 21px;
    text-align: left;
    letter-spacing: 0px;
    color: #232940;
    border: 1px solid #CBCBCB;
    border-radius: 8px;
    }
    
    .email-input {
    margin-bottom:15px;
    }
    

    @media only screen and (max-width: 768px) {

        input[type=email], input[type=password] {
            font-size:1em;
            padding: 12px 0 12px 21px;
            text-align: left;
            letter-spacing: 0px;
            color: #232940;
            border: 1px solid #CBCBCB;
            border-radius: 8px;
            }
            
            .email-input {
            margin-bottom:15px;
            }
        }`, "",{"version":3,"sources":["webpack://./src/common/accounts/login/InputField/InputField.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB;;IAEA;IACA,kBAAkB;IAClB;;;IAGA;;QAEI;YACI,aAAa;YACb,yBAAyB;YACzB,gBAAgB;YAChB,mBAAmB;YACnB,cAAc;YACd,yBAAyB;YACzB,kBAAkB;YAClB;;YAEA;YACA,kBAAkB;YAClB;QACJ","sourcesContent":["input[type=email], input[type=password] {\n    font-size: 0.875em;\n    padding: 12px 0 12px 21px;\n    text-align: left;\n    letter-spacing: 0px;\n    color: #232940;\n    border: 1px solid #CBCBCB;\n    border-radius: 8px;\n    }\n    \n    .email-input {\n    margin-bottom:15px;\n    }\n    \n\n    @media only screen and (max-width: 768px) {\n\n        input[type=email], input[type=password] {\n            font-size:1em;\n            padding: 12px 0 12px 21px;\n            text-align: left;\n            letter-spacing: 0px;\n            color: #232940;\n            border: 1px solid #CBCBCB;\n            border-radius: 8px;\n            }\n            \n            .email-input {\n            margin-bottom:15px;\n            }\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
