import React, { useState, useEffect, useContext, useCallback } from "react";
import "./filter.css";
import { Link } from "react-router-dom";

import AgricultureIcon from "./assets/images/AgricultureIcon.png";
import CartographyIcon from "./assets/images/CartographyIcon.png";
import CommercialIcon from "./assets/images/CommercialIcon.png";
import ConstructionIcon from "./assets/images/Constructions.png";
import EnergyIcon from "./assets/images/EnergyIcon.png";
import EventsIcon from "./assets/images/EventsIcon.png";
import RealEstateIcon from "./assets/images/RealEstateIcon.png";
import FilterButton from "./assets/images/FilterRectangle.png";
import DroneImage from "./assets/images/dronetest.png";
import FilterIcon from "./assets/images/FilterIcon.png";

import { UserContext } from "../../common/accounts/context/UserContext";

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

type Drone = {
  id: number;
  name: string;
  postedBy: User;
  price: number;
  category: string[];
  dateTime?: string;
  location: string;
  description: string;
  brand?: string;
  model?: string;
  imageUrls: string[];
  displayImageUrl?: string;
};

function FilterPage({
  searchResults,
  searchQuery,
  locationSearchQuery, // Adăugarea parametrului locationSearchQuery
}: {
  searchResults: any[];
  searchQuery: string;
  locationSearchQuery: string; // Adăugarea tipului pentru locationSearchQuery
}) {
  const { userData, images } = useContext(UserContext);
  const [drones, setDrones] = useState<Drone[]>([]);
  const [visibleDrones, setVisibleDrones] = useState(8);
  const [loadClicks, setLoadClicks] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const categories = [
    { icon: AgricultureIcon, label: "Agriculture" },
    { icon: CartographyIcon, label: "Cartography" },
    { icon: CommercialIcon, label: "Commercial" },
    { icon: ConstructionIcon, label: "Construction" },
    { icon: EnergyIcon, label: "Energy" },
    { icon: EventsIcon, label: "Events" },
    { icon: RealEstateIcon, label: "Real Estate" },
  ];

  const [selected, setSelected] = useState(
    Array(categories.length).fill(false)
  );

  const formatDate = (dateString?: string) => {
    if (!dateString) {
      return "No Date Provided";
    }

    const date = new Date(dateString + "Z");

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    fetch("https://staging.droneden.ro:8080/api/drones")
      .then((response) => response.json())
      .then(async (data) => {
        const updatedData = await Promise.all(
          data.map(async (drone: Drone) => {
            if (drone.imageUrls && drone.imageUrls.length > 0) {
              try {
                const response = await fetch(
                  `https://staging.droneden.ro:8080/file/download?fileName=${drone.imageUrls[0]}`
                );
                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                return { ...drone, displayImageUrl: imageUrl };
              } catch (error) {
                console.error("Failed to fetch image: ", error);
                return drone; // return original drone object if fetch fails
              }
            } else {
              return drone; // return original drone object if no imageUrls
            }
          })
        );
        setDrones(updatedData);
      })
      .catch((error) => {
        console.error("Failed to fetch drones: ", error);
      });
  }, []);

  const filterDrones = useCallback(() => {
    let filteredDrones = drones;

    if (searchResults.length > 0) {
      filteredDrones = searchResults;
    }

    if (selectedCategories.length > 0) {
      filteredDrones = filteredDrones.filter((drone) =>
        drone.category.some((cat, index) => cat === "true" && selected[index])
      );
    }

    if (searchQuery) {
      filteredDrones = filteredDrones.filter((drone) =>
        drone.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (locationSearchQuery) {
      filteredDrones = filteredDrones.filter(
        (drone) =>
          drone.location.toLowerCase() === locationSearchQuery.toLowerCase()
      );
    }

    return filteredDrones;
  }, [
    drones,
    searchResults,
    selectedCategories,
    searchQuery,
    locationSearchQuery,
  ]);

  const handleLoadMore = () => {
    if (loadClicks < 3) {
      setVisibleDrones(visibleDrones + 5);
      setLoadClicks(loadClicks + 1);
    }
  };

  useEffect(() => {
    setVisibleDrones(10);
    setLoadClicks(0);
    filterDrones();
  }, [searchQuery, locationSearchQuery, filterDrones]);

  const toggleCategory = (index: number) => {
    const newSelected = [...selected];
    newSelected[index] = !newSelected[index];
    setSelected(newSelected);

    const selectedCategoriesLabels = categories
      .filter((_, idx) => newSelected[idx])
      .map((category) => category.label);

    setSelectedCategories(selectedCategoriesLabels);
  };

  return (
    <div className="HeaderFilter">
      <header className="Header-Rectangle">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`HeaderButton ${selected[index] ? "selected" : ""}`}
            onClick={() => toggleCategory(index)}
          >
            <img src={category.icon} alt={category.label} />
            <p className="TextFilters"> {category.label} </p>
          </button>
        ))}
        <button
          className={`FilterButton ${
            selectedCategories.length > 0
              ? "selected filterButton"
              : "filterButton"
          }`}
          onClick={() => {
            setSelected(Array(categories.length).fill(false));
            setSelectedCategories([]);
          }}
        >
          <img src={FilterButton} alt="Filter" />
          <img src={FilterIcon} alt="Filter Icon" className="filtericon" />
          <p className="FilterText">Filters</p>
          {selectedCategories.length > 0 && (
            <span className="Badge">{selectedCategories.length}</span>
          )}
        </button>
        <div className="HeaderRectangleOverlay"></div>
      </header>
      <div
        className="droneListingsContainer"
        style={{
          maxHeight: `calc(18 + ${loadClicks} * 9)`,
        }}
      >
        {filterDrones()
          .slice(0, visibleDrones)
          .map((drone) => (
            <Link
              to={`/product/${drone.id}`}
              key={drone.id}
              className="white-rectangle-link"
            >
              <div className="WhiteRectangle">
                <div className="elements">
                  <img
                    src={
                      drone.displayImageUrl || drone.imageUrls[0] || DroneImage
                    }
                    alt="Drone"
                    className="PlaceholderImage1"
                  />
                  <p className="PlaceholderTitle1">{drone.name}</p>

                  <div className="CityAndDateContainer">
                    <div className="CityLocation">{drone.location} -&nbsp;</div>
                    <p className="PlaceholderLocation1">
                      {formatDate(drone.dateTime)}
                    </p>
                  </div>
                  <div className="details-section">
                    <div className="RectangleGrey1"></div>
                    <div className="ProfilePicture1"></div>
                    <p className="FirstNameRow1">
                      {drone.postedBy ? drone.postedBy.firstName : "Unknown"}
                    </p>
                  </div>
                  <p className="PriceTag1">
                    <b>{drone.price}$</b> day
                  </p>
                </div>
              </div>
            </Link>
          ))}
      </div>

      <div className="ButtonContainer">
        {drones.length > visibleDrones && loadClicks < 3 && (
          <button className="BottomButton" onClick={handleLoadMore}>
            Load More
          </button>
        )}
      </div>
    </div>
  );
}

export default FilterPage;
