// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
}

.Header-Container {
  display: flex;
  flex-direction: column;
}

.Image-Row-One, .Image-Row-Two {
  display: flex;
  margin-top: 20px; 
  justify-content: center;
  flex-wrap: wrap;
  z-index: 0;
}

.Image-Text-Container, .Image-Text-Container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 9px;
  z-index: 0;
}

.IMAGETEXT1 {
  margin-top: 26px;
}

.IMAGETEXT2 {
  margin-top: 26px;
}

.Bottom-Image {
  width: 100%;
  z-index: -1;
  margin-top: -220px;
}

.Bottom-Img {
    width: 100%;
}

@media (max-width: 1024px) {
  .Header-Image {
    padding: 10px;
  }

  .Image-Row-One, .Image-Row-Two, .Image-Text-Container, .Image-Text-Container2, .Bottom-Image  {
    flex-direction: column;
    align-items: center;
  }

  .Image-Row-One, .Image-Row-Two, .Image-Text-Container, .Image-Text-Container2 {
    margin: 9px 0;
  }
  .Bottom-Image {
    display: none;
  }
}


`, "",{"version":3,"sources":["webpack://./src/homepage/branding/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".Header-Image {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  color: white;\n}\n\n.Header-Container {\n  display: flex;\n  flex-direction: column;\n}\n\n.Image-Row-One, .Image-Row-Two {\n  display: flex;\n  margin-top: 20px; \n  justify-content: center;\n  flex-wrap: wrap;\n  z-index: 0;\n}\n\n.Image-Text-Container, .Image-Text-Container2 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-right: 9px;\n  z-index: 0;\n}\n\n.IMAGETEXT1 {\n  margin-top: 26px;\n}\n\n.IMAGETEXT2 {\n  margin-top: 26px;\n}\n\n.Bottom-Image {\n  width: 100%;\n  z-index: -1;\n  margin-top: -220px;\n}\n\n.Bottom-Img {\n    width: 100%;\n}\n\n@media (max-width: 1024px) {\n  .Header-Image {\n    padding: 10px;\n  }\n\n  .Image-Row-One, .Image-Row-Two, .Image-Text-Container, .Image-Text-Container2, .Bottom-Image  {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .Image-Row-One, .Image-Row-Two, .Image-Text-Container, .Image-Text-Container2 {\n    margin: 9px 0;\n  }\n  .Bottom-Image {\n    display: none;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
