import React, { useContext, useEffect } from "react";
import "./renting_step.css";
import { StepperContext } from "../stepper/stepper_context";

const RentingStep = () => {
  const { droneData, droneId, setDroneData } = useContext(StepperContext)!;

  const handleExtraDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (droneData) {
      const newExtraDetails = e.target.value;
      const updatedDroneData = {
        ...droneData,
        extraDetails: newExtraDetails,
      };
      setDroneData(updatedDroneData);
    }
  };

  return (
    <div className="renting-step-wrapper">
      <div className="header-text-renting-time">
        <h2>Before we finalize...when can people start renting?</h2>
        <p>
          You can use this calendar to specify the start date of your
          advertisement and indicate any dates when the item is unavailable for
          rental.
        </p>
        <div className="text-area-field">
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            className="text-area-input"
            placeholder="Enter any additional information here"
            value={droneData?.extraDetails || ""}
            onChange={handleExtraDetailsChange}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default RentingStep;
