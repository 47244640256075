import React, { useContext, useState, useEffect } from "react";
import "./my_listings_tab.css";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";

const ListingsTab = () => {
  const { userData, fetchUserDrones, userDrones } = useContext(UserContext);

  useEffect(() => {
    if (userData && userData.id) {
      fetchUserDrones(userData.id);
    }
  }, [userData, fetchUserDrones]);

  return (
    <div className="listings-tab-wrapp">
      {userDrones.map((drone) => (
        <Link key={drone.id} to={`/product/${drone.id}`}>
          <div className="drone-listing-tab">
            <div className="drone-title-name">{drone.name}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ListingsTab;
