import React, { useState } from "react";
import "./category_select.css";
import CategoryCards from "../common/CategoryCards/category_cards";

const CategoryStep: React.FC = () => {
  return (
    <div className="category-body">
      <h2 className="category-title">
        Choose categories you want your drone to be featured
      </h2>
      <CategoryCards />
    </div>
  );
};

export default CategoryStep;
