import React, { useState } from "react";
import "./drone_mate.css";
import DroneMateIcon from "./assets/DroneMate.png";

import DroneMateBody from "./components/DroneMainBody/drone_mate_body";

const DroneMate = () => {
  const [showDroneMateBody, setShowDroneMateBody] = useState(false);
  const handleClick = () => {
    setShowDroneMateBody(!showDroneMateBody);
  };

  return (
    <div>
      <div className="drone-mate-button" onClick={handleClick}>
        <img
          src={DroneMateIcon}
          alt="Drone Mate Button"
          className="drone-mate-icon"
        />
      </div>
      {showDroneMateBody && <DroneMateBody />}
    </div>
  );
};

export default DroneMate;
