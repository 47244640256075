import React, { useState } from "react";
import "./drone_mate_footer.css";

import { DroneMateService } from "../../drone_mate_service";

interface Props {
  service: DroneMateService;
}

const DroneMateFooter: React.FC<Props> = ({ service }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSend = () => {
    service.userSendMessage(inputValue);
    setInputValue("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="drone-mate-footer-wrapper">
      <div className="drone-mate-chat-footer">
        <input
          className="drone-mate-footer-input"
          type="text"
          placeholder="Ask DroneMate anything"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress} // Adăugat aici
        />
        <button className="drone-mate-send-msg" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default DroneMateFooter;
