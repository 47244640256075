import React, { useState, useRef, useContext, useEffect } from "react";

import "./image_step.css";
import { StepperContext } from "../stepper/stepper_context";

import TrashCanIcon from "../../assets/images/trashcan.png";
import CommercialIcon from "../../assets/images/edit-photo.png";

const EditImages = () => {
  console.log("EditImages re-rendered");
  const {
    droneData,
    fetchImageUrls,
    uploadedImages,
    imageFiles,
    setImageFiles,
    existingImages,
    setExistingImages,
  } = useContext(StepperContext)!;

  const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
  const [images, setImages] = useState<string[]>([]);

  const [currentActiveMiniature, setCurrentActiveMiniature] = useState<
    number | null
  >(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  function dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleCoverPhotoUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        if (base64 && typeof base64 === "string") {
          const blob = dataURItoBlob(base64);
          const newFile = new File([blob], `image-0.jpg`, { type: blob.type }); // Aici am schimbat numele
          setImageFiles((prevFiles) => [newFile, ...prevFiles.slice(1)]); // Înlocuiește primul element (cover photo)
          setCoverPhoto(base64);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (index: number) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files![0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64 && typeof base64 === "string") {
            const blob = dataURItoBlob(base64);
            const newFile = new File([blob], `image-${index}.jpg`, {
              type: blob.type,
            });

            setImageFiles((prevFiles) => {
              const newFiles = [...prevFiles];
              newFiles[index] = newFile;
              return newFiles;
            });

            setImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = base64;
              return newImages;
            });

            // Actualizează existingImages păstrând celelalte imagini neschimbate
            setExistingImages((prevExistingImages) => {
              const newExistingImages = [...prevExistingImages];
              newExistingImages[index] = base64;
              return newExistingImages;
            });

            setCurrentActiveMiniature(index + 1);
          }
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const handleImageDelete = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, idx) => idx !== index));

    setImageFiles((prevFiles) => prevFiles.filter((_, idx) => idx !== index));

    setCurrentActiveMiniature((prev) => (prev !== null ? prev - 1 : null));
  };

  const handleCoverPhotoDelete = () => {
    setCoverPhoto(null);
  };

  useEffect(() => {
    if (existingImages.length > 0) {
      setImages(existingImages);

      // Descarcă imaginile dacă sunt URL-uri
      Promise.all(existingImages.map((url, index) => fetch(url)))
        .then((responses) => Promise.all(responses.map((res) => res.blob())))
        .then((blobs) => {
          const initialFiles = blobs.map((blob, index) => {
            return new File([blob], `image-${index}.jpg`, { type: blob.type });
          });
          setImageFiles(initialFiles);
        })
        .catch((error) =>
          console.error("A apărut o eroare la descărcarea imaginilor:", error)
        );
    }
  }, [existingImages]);

  useEffect(() => {
    if (droneData && droneData.imageUrls.length > 0 && images.length === 0) {
      fetchImageUrls(droneData.imageUrls).then((urls) => {
        setCoverPhoto(urls[0]);
        setImages(urls);
        if (urls.length < 5) {
          setCurrentActiveMiniature(urls.length);
        }
      });
    }
  }, [droneData, fetchImageUrls, images.length]);

  useEffect(() => {
    if (uploadedImages.length > 0) {
      setImages(uploadedImages);
      if (uploadedImages.length < 5) {
        setCurrentActiveMiniature(uploadedImages.length);
        console.log("Uploaded Images changed:", uploadedImages);
      }
    }
  }, [uploadedImages]);

  return (
    <div className="image-upload-zone">
      <div className="image-upload-step-text">
        <h2>Add at least 5 photos to showcase your work</h2>
        <p>
          People react to images so make sure to use your best shots of the
          product
        </p>
      </div>
      <div
        className="image-upload-cover-photo"
        onClick={() => fileInputRef.current?.click()}
      >
        {coverPhoto ? (
          <>
            <img
              src={coverPhoto}
              alt="Cover Photo"
              className="cover-photo-drone"
            />
            <div
              className="trashcan-div"
              onClick={(e) => {
                e.stopPropagation();
                handleCoverPhotoDelete();
              }}
            >
              <img src={TrashCanIcon} alt="Delete" className="trashcan-icon" />
            </div>
          </>
        ) : (
          <div className="image-upload-text-icon">
            <img src={CommercialIcon} alt="" />
            <h2>Drag photos here</h2>
            <p>or</p>
            <p className="upload-link">Upload</p>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleCoverPhotoUpload}
          style={{ display: "none" }}
        />
      </div>
      <div className="image-upload-miniature-cards">
        {images.slice(1).map(
          (
            image,
            index // folosește slice(1) pentru a exclude coverPhoto
          ) => (
            <div
              key={index}
              className={`image-upload-miniature ${
                index + 1 <= (currentActiveMiniature ?? -1)
                  ? "active"
                  : "inactive"
              }`}
              style={{
                backgroundImage: image ? `url(${image})` : undefined,
              }}
              onClick={() => coverPhoto && handleImageUpload(index + 1)} // folosește index + 1 aici
            >
              {image && (
                <div
                  className="trashcan-div"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageDelete(index + 1); // și aici
                  }}
                >
                  <img
                    src={TrashCanIcon}
                    alt="Delete"
                    className="trashcan-icon"
                  />
                </div>
              )}
              {!image && coverPhoto && index + 1 === currentActiveMiniature && (
                <div>Your placeholder here</div>
              )}
            </div>
          )
        )}
        {currentActiveMiniature !== null && currentActiveMiniature < 5 && (
          <div
            className={`image-upload-miniature ${
              currentActiveMiniature === images.length ? "active" : "inactive"
            }`}
            onClick={() => coverPhoto && handleImageUpload(images.length)}
          >
            <div className="overlay-empty-miniature">+Add more</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditImages;
