import React, { useState, useContext } from "react";
import envelope from "./assets/images/Item1.png";
import scribble from "./assets/images/Item2.png";
import "./calltoaction.css";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../common/accounts/context/UserContext";

function CalltoAction() {
  const { isLoggedIn = false, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();

  return (
    <div className="call-to-action-wrapper">
      <div className="call-to-action-container">
        <div className="left-side-container">
          <img src={envelope} alt="" />
        </div>
        <div className="right-side-container">
          <h3>Monetize your drone by renting it out</h3>
          <p>
            Not only can renting out your drone be a lucrative side hustle, but
            it can also help offset the cost of owning a drone. Plus, you’ll
            have the satisfaction of knowing that your drone is being put to
            good use and helping others achieve their goals.
          </p>
          <button
            className="call-to-action-rent-out-btn"
            onClick={() => {
              if (isLoggedIn) {
                history.push("/rent-out-drones");
              } else {
                // Opțional: afișează un mesaj sau un modal care informează utilizatorul că trebuie să fie autentificat
                alert(
                  "Trebuie să fiți autentificat pentru a accesa această funcționalitate."
                );
              }
            }}
          >
            Rent out
          </button>
        </div>
      </div>
      <img className="scribble-image" src={scribble} alt="scribble image" />
    </div>
  );
}

export default CalltoAction;
