import React from "react";
import "./CloseButton.css";

interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <button className="modal-close-btn" onClick={onClick}>
      X
    </button>
  );
};

export default CloseButton;
