// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-page {
    display: flex;
    flex-direction: column;
}

.horizontal-summary-separator {
    border: 1px solid #CBCBCB;
    opacity: 0.2;
    width: 100%;
    margin-top: 100px;
}

.horizontal-component-separator {
    border: 1px solid #CBCBCB;
    opacity: 0.5;
    width: 100%;
    margin-top: 10px;
}

.summary-main-area {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/checkout/checkout_page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;AAClB","sourcesContent":[".checkout-page {\n    display: flex;\n    flex-direction: column;\n}\n\n.horizontal-summary-separator {\n    border: 1px solid #CBCBCB;\n    opacity: 0.2;\n    width: 100%;\n    margin-top: 100px;\n}\n\n.horizontal-component-separator {\n    border: 1px solid #CBCBCB;\n    opacity: 0.5;\n    width: 100%;\n    margin-top: 10px;\n}\n\n.summary-main-area {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
