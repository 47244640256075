import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext, User, Message } from "../../../context/UserContext";
import "./message_tab.css";
import axios from "axios";
import { error } from "console";

const MessageTab = () => {
  const {
    sendMessage,
    fetchChatIds,
    userData,
    chatIds,
    fetchConversation,
    conversation,
    setConversation,
  } = useContext(UserContext);
  const [users, setUsers] = useState<User[]>([]); // Atenție: am folosit 'users' aici, nu 'user'
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const conversationRef = useRef<HTMLDivElement>(null);
  const [localUserId, setLocalUserId] = useState<number | null>(null); // Stare nouă pentru userId local
  const [userDetails, setUserDetails] = useState<{ [key: number]: User }>({});
  const [visitedTabs, setVisitedTabs] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [userScrolled, setUserScrolled] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };
  const handleSendMessage = async () => {
    if (selectedUserId !== null && userData) {
      const sender: User = {
        id: userData.id,
        name: `${userData.firstName} ${userData.lastName}`,
      };

      const receiver: User = {
        id: selectedUserId,
        name: `User${selectedUserId}`,
      };

      const message: Message = {
        id: new Date().getTime(),
        sender,
        receiver,
        content: newMessage,
        timestamp: new Date().toISOString(),
      };

      // Trimiterea mesajului
      await sendMessage(message as any);

      // Actualizarea manuală a stării 'conversation'
      setConversation((prevConversation) => [...prevConversation, message]);

      // Resetarea câmpului de introducere a mesajului
      setNewMessage("");
    }
  };

  const handleUserTabClick = async (userId: number) => {
    setSelectedUserId(userId);

    // Încărcați conversația pentru utilizatorul selectat
    if (userData && userData.id) {
      await fetchConversation(userData.id, userId);
    }
  };

  useEffect(() => {
    // Când utilizaorul primește un mesaj nou, setați corespunzător obiectul 'visitedTabs'
    if (conversation.length > 0 && userData) {
      const lastMessage = conversation[conversation.length - 1];
      setVisitedTabs((prev) => ({
        ...prev,
        [lastMessage.sender.id]: lastMessage.sender.id === selectedUserId,
      }));
    }
  }, [conversation, userData, selectedUserId]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Preveniți comportamentul implicit al tastei Enter
      handleSendMessage();
    }
  };

  useEffect(() => {
    // Preia userId din localStorage și îl setează în stare
    const localUserData = localStorage.getItem("userData");
    if (localUserData) {
      const parsedData = JSON.parse(localUserData);
      setLocalUserId(parsedData.id); // Asumăm că obiectul de date ale utilizatorului are un câmp "id"
    }
  }, []);

  useEffect(() => {
    if (localUserId && users.length === 0) {
      fetchChatIds(localUserId).then(async () => {
        const newUsers = chatIds.map((id: number) => ({
          id,
          name: `User${id}`,
        }));
        setUsers(newUsers);

        // Fetch details for each user
        const details: { [key: number]: User } = {};
        for (const id of chatIds) {
          const response = await axios.get(
            `https://staging.droneden.ro:8080/api/users/${id}`
          );
          if (response.status === 200) {
            details[id] = response.data;
          }
        }
        setUserDetails(details);
      });
    }
  }, [localUserId, fetchChatIds, chatIds, users]);

  useEffect(() => {
    if (conversationRef.current && !userScrolled) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [conversation, userScrolled]);

  useEffect(() => {
    const fetchNewMessages = async () => {
      if (selectedUserId !== null && userData?.id) {
        await fetchConversation(userData.id, selectedUserId);
      }
    };

    const intervalId = setInterval(fetchNewMessages, 1000);

    return () => {
      clearInterval(intervalId); // Curăță intervalul când componenta se demontează
    };
  }, [selectedUserId, userData, fetchConversation]);

  return (
    <div className="message-tab-wrapper">
      <div className="left-panel">
        {chatIds.map((id) => (
          <div
            key={id}
            className={`user-message-tab ${
              id === selectedUserId ? "selected" : ""
            }`}
            onClick={() => handleUserTabClick(id)}
          >
            <p className="user-name">
              {userDetails[id]?.firstName} {userDetails[id]?.lastName}
              {visitedTabs[id] && id !== selectedUserId && " (New Message)"}
            </p>
          </div>
        ))}
      </div>

      <div className="message-panel">
        <div
          className="conversation-area"
          ref={conversationRef}
          onScroll={(e) => {
            const element = e.target as HTMLDivElement;
            if (
              element.scrollTop + element.clientHeight <
              element.scrollHeight
            ) {
              setUserScrolled(true);
            } else {
              setUserScrolled(false);
            }
          }}
        >
          {conversation.map((message, index) => (
            <div
              key={index}
              className={
                message.sender.id === userData?.id
                  ? "user-message-sent"
                  : "client-message-sent"
              }
            >
              <p>{message.content}</p>
            </div>
          ))}
        </div>
        <div className="message-footer-section">
          <div className="message-footer-input">
            <input
              type="text"
              value={newMessage}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress} // Adăugați handler-ul aici
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageTab;
