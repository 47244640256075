import React from "react";
import "./drone_mate_questions.css";
import { DroneMateService } from "../../drone_mate_service"; // Adaugă importul

interface Props {
  service: DroneMateService;
}

const DroneMateQuestions: React.FC<Props> = ({ service }) => {
  const handleQuestionClick = (question: string) => {
    service.userSendMessage(question);
  };

  return (
    <div className="set-questions">
      <div
        className="question"
        onClick={() => handleQuestionClick("What is DroneDen?")}
      >
        What is DroneDen?
      </div>
      <div
        className="question"
        onClick={() => handleQuestionClick("How does DroneDen work?")}
      >
        How does DroneDen work?
      </div>
      <div
        className="question"
        onClick={() =>
          handleQuestionClick(
            "What can I do with the drones rented from DroneDen?"
          )
        }
      >
        What can I do with the drones rented from DroneDen?
      </div>
      <div
        className="question"
        onClick={() => handleQuestionClick("Can I make money with DroneDen?")}
      >
        Can I make money with DroneDen?
      </div>
      <div
        className="question"
        onClick={() =>
          handleQuestionClick("Why should I rent out my drone on DroneDen?")
        }
      >
        Why should I rent out my drone on DroneDen?
      </div>
    </div>
  );
};

export default DroneMateQuestions;
