// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drone-mate-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 100px;
    height: 100px;
    
}

.drone-mate-icon {
    width: 100%;
    
}`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/drone_mate.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;;AAEjB;;AAEA;IACI,WAAW;;AAEf","sourcesContent":[".drone-mate-button {\n    position: fixed;\n    bottom: 50px;\n    right: 50px;\n    width: 100px;\n    height: 100px;\n    \n}\n\n.drone-mate-icon {\n    width: 100%;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
