import React, { useState } from "react";
import "./ResetPasswordPage.css";

function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [responseOk, setResponseOk] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const params = new URLSearchParams();
  params.append("email", email);
  params.append("newPassword", password);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setMessage(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    if (password === confirmPassword) {
      try {
        const response = await fetch(
          "https://staging.droneden.ro:8080/api/password-reset/reset",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: params.toString(),
          }
        );

        if (response.ok) {
          setResponseOk(true); // Set responseOk to true for a successful response
          setMessage("Password reset successfully");
        } else {
          setResponseOk(false); // Set responseOk to false for an unsuccessful response
          setMessage("Failed to reset password");
        }
      } catch (error) {
        setResponseOk(false); // Set responseOk to false for an error
        setMessage("An error occurred while resetting the password");
      }
    } else {
      setMessage("Passwords do not match");
    }
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <div>
          <label>
            E-Mail
            <input
              type="email"
              required
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label>
            New Password:
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              title="Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
              required
            />
          </label>
        </div>
        <div>
          <label>
            Confirm New Password:
            <input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
          </label>
        </div>
        <div>
          <button
            type="submit"
            disabled={!email || !password || !confirmPassword}
          >
            Reset Password
          </button>
        </div>
        <p className={`message ${message && responseOk ? "success" : ""}`}>
          {message}
        </p>
      </form>
    </div>
  );
}

export default ResetPasswordPage;
