import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./edit_drones.css";
import { StepperContext } from "./components/stepper/stepper_context";

import EditDroneHeader from "./components/EditDroneHeader/edit_drone_header";
import EditDroneFooter from "./components/edit_drone_footer/edit_drone_footer";
import CategoryStep from "./components/category_select/category_select";
import NameDrone from "./components/NameAdvertisement/name_drone";
import DescriptionStep from "./components/DescriptionStep/description_step";
import SpecStep from "./components/SpecsStep/specs_step";
import ExtraGearsStep from "./components/ExtraGear/extra_gear";
import PriceStep from "./components/PriceStep/price_step";
import LocationStep from "./components/LocationStep/location_step";
import EditImages from "./components/ImageStep/image_step";
import RentingStep from "./components/RentingStep/renting_step";

interface MatchParams {
  id: string;
}

interface EditDronesProps extends RouteComponentProps<MatchParams> {}

function EditDrones({ match }: EditDronesProps) {
  const id = Number(match.params.id);
  const { setDroneId, currentStep, fetchDroneDetails } =
    useContext(StepperContext)!;

  useEffect(() => {
    setDroneId(id);
  }, [id, setDroneId]);

  useEffect(() => {
    setDroneId(id);
    fetchDroneDetails(id);
  }, [id, setDroneId, fetchDroneDetails]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <CategoryStep />;
      case 2:
        return <NameDrone />;
      case 3:
        return <DescriptionStep />;
      case 4:
        return <SpecStep />;
      case 5:
        return <ExtraGearsStep />;
      case 6:
        return <PriceStep />;
      case 7:
        return <LocationStep />;
      case 8:
        return <EditImages />;
      case 9:
        return <RentingStep />;
      default:
        return <div>Invalid step</div>;
    }
  };

  return (
    <div className="edit-drones-body">
      <EditDroneHeader />
      <div>{renderStep()}</div>
      <EditDroneFooter />
    </div>
  );
}

export default EditDrones;
