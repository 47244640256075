import React, { useContext, useState, useEffect } from "react";
import "./accountsettings.css";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import TabNavigation from "./components/TabNavigation/tab_navigation";
import AccountSettingsTab from "./components/SettingsTab/account_settings_tab";
import MessageTab from "./components/MessageTab/message_tab";
import ListingsTab from "./components/MyListingsTab/my_listings_tab";
import { Link } from "react-router-dom";

interface AccountSettingsProps {
  isVisible: boolean;
  onClose: () => void;
  activeTab?: string;
  setActiveTab: (tabName: string) => void; // Prop nou
}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  isVisible,
  onClose,
  activeTab = "Account Settings",
  setActiveTab, // Prop nou
}) => {
  const [activeTabState, setActiveTabState] = useState(activeTab);
  const { setIsLoggedIn, setUserData } = useContext(UserContext); // Adaugă aceste linii
  const history = useHistory();

  useEffect(() => {
    setActiveTabState(activeTab);
  }, [activeTab]);

  const handleTabClick = (tabName: string) => {
    setActiveTabState(tabName);
    setActiveTab(tabName); // Update parent state
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
    onClose();
    history.push("/");
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Account Settings":
        return <AccountSettingsTab handleLogout={handleLogout} />;
      case "Messages":
        return <MessageTab />;
      case "My Listings":
        return <ListingsTab />; // sau un alt component pentru My Listings
      default:
        return <p>Placeholder text for {activeTab}</p>;
    }
  };

  return isVisible ? (
    <div className="account-settings-overlay">
      <div className="account-settings-wrapper">
        <button className="account-settings-modal-close-btn" onClick={onClose}>
          X
        </button>
        <TabNavigation activeTab={activeTab} handleTabClick={handleTabClick} />
        {renderTabContent()}
      </div>
    </div>
  ) : null;
};

export default AccountSettings;
