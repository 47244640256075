import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "./edit_drone_footer.css";
import { StepperContext } from "../stepper/stepper_context";

export const EditDroneFooter: React.FC = () => {
  const { currentStep, setCurrentStep, saveDroneChanges, droneData } =
    useContext(StepperContext)!;
  const totalSteps = 10;
  const progressPerStep = 100 / totalSteps;
  const stepCompletion = progressPerStep * currentStep;
  const history = useHistory();

  const handleNext = async () => {
    if (currentStep === 9) {
      try {
        if (droneData) {
          await saveDroneChanges(droneData);
          history.push("/");
        } else {
          alert("No drone data to save");
        }
      } catch (error) {
        alert("An error occurred while saving the drone");
      }
    } else {
      setCurrentStep(currentStep + 1); // Dacă nu suntem la ultimul pas, mergem mai departe
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Mergi la pasul anterior
    }
  };

  return (
    <footer className="edit-drone-footer">
      <div className="edit-drone-footer-wrapper">
        <div className="footer-progress-bar">
          {[25, 50, 75, 100].map((limit, index) => (
            <div key={index} className="line-bar">
              <div
                className="fill"
                style={{
                  width: `${
                    stepCompletion >= limit
                      ? 100
                      : stepCompletion > limit - 25
                      ? (stepCompletion - (limit - 25)) * 4
                      : 0
                  }%`,
                }}
              />
            </div>
          ))}
        </div>
        <div className="footer-buttons">
          <button className="back-btn-drone-creation" onClick={handleBack}>
            Back
          </button>
          <button className="continue-btn-drone-creation" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </footer>
  );
};

export default EditDroneFooter;
