import React from "react";
import footerlogo from "./images/logo-footer.png";
import footerimage from "./images/footer-shape.png";
import facebookicon from "./images/facebook-icon.png";
import instagramicon from "./images/instagram-icon.png";
import "./footer.css";

function FooterSection() {
  return (
    <footer className="footer-wrapper">
      <div className="footer-container">
        <div className="left-side-footer">
          <div className="droneden-footer-logo">
            <img src={footerlogo} alt="DroneDen Logo" />
          </div>
          <div className="social-media-icons">
            <img src={facebookicon} alt="DroneDen Logo" />
            <img src={instagramicon} alt="DroneDen Logo" />
          </div>
        </div>
        <div className="links">
          <ul>
            <li>Rent Drones</li>
            <li>Subscribe</li>
            <li>Rent out Drones</li>
          </ul>
          <ul>
            <li>How it works</li>
            <li>About us</li>
          </ul>
          <ul>
            <li>Contact</li>
            <li>contact@droneden.ro</li>
            <li>Phone: +40729013101</li>
          </ul>
        </div>
      </div>
      <p className="copyright">© DroneDen 2023</p>
      <img className="footer-image" src={footerimage} alt="" />
    </footer>
  );
}

export default FooterSection;
