// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drone-mate-header {
    box-sizing: border-box;
    background-color: #232940;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 60px;
    border-radius: 20px 20px 0 0;
    position: relative;
}

.drone-mate-header h2 {
    margin: 0;
}

.drone-mate-header-icon {
    position: absolute;
    top: -20px;
    left: 10px;
    width: 70px;
    height: 70px;

}

.drone-mate-header-icon img {
    background-color: white;
    border: 2px solid #232940;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/components/DroneHeader/drone_mate_header.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".drone-mate-header {\n    box-sizing: border-box;\n    background-color: #232940;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    max-height: 60px;\n    border-radius: 20px 20px 0 0;\n    position: relative;\n}\n\n.drone-mate-header h2 {\n    margin: 0;\n}\n\n.drone-mate-header-icon {\n    position: absolute;\n    top: -20px;\n    left: 10px;\n    width: 70px;\n    height: 70px;\n\n}\n\n.drone-mate-header-icon img {\n    background-color: white;\n    border: 2px solid #232940;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
