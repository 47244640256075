// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import headerImage from "./assets/images/Brands-we-love.png";
import DJIDrone from "./assets/images/Dji.png";
import ParrotDrone from "./assets/images/Parrot.png";
import AutelDrone from "./assets/images/Autel-Robotics.png";
import YuneecDrone from "./assets/images/Yuneec.png";
import GoProDrone from "./assets/images/GoPro.png";
import PowerVisionDrone from "./assets/images/PowerVision.png";
import UbsanDrone from "./assets/images/Ubsan.png";
import DJIText from "./assets/images/Text-Dji.png";
import ParrotText from "./assets/images/Text-Parrot.png";
import AutelText from "./assets/images/Text-Autel.png";
import YuneecText from "./assets/images/Text-Yuneec.png";
import GoProText from "./assets/images/Text-GoPro.png";
import PowerVisionText from "./assets/images/Text-PowerVision.png";
import UbsanText from "./assets/images/Text-Ubsan.png";
import BottomImage from "./assets/images/Rectangle107.png";

function BrandingList() {
  return (
    <div className="Header-Container">
      <header className="Header-Image">
        <img src={headerImage} alt="Header Image" />
      </header>
      <div className="Image-Row-One">
        <div className="Image-Text-Container">
          <img src={DJIDrone} alt="DJIDRONEIMAGE" className="IMAGE" />
          <img src={DJIText} alt="DJITEXT" className="IMAGETEXT1" />
        </div>
        <div className="Image-Text-Container">
          <img src={ParrotDrone} alt="PARROTDRONEIMAGE" className="IMAGE" />
          <img src={ParrotText} alt="PARROTTEXT" className="IMAGETEXT1" />
        </div>
        <div className="Image-Text-Container">
          <img src={AutelDrone} alt="AUTELDRONEIMAGE" className="IMAGE" />
          <img src={AutelText} alt="AUTELTEXT" className="IMAGETEXT1" />
        </div>
        <div className="Image-Text-Container">
          <img src={YuneecDrone} alt="YUNEECDRONEIMAGE" className="IMAGE" />
          <img src={YuneecText} alt="YUNEECTEXT" className="IMAGETEXT1" />
        </div>
      </div>
      <div className="Image-Row-Two">
        <div className="Image-Text-Container2">
          <img src={GoProDrone} alt="DJIDRONEIMAGE" className="IMAGE2" />
          <img src={GoProText} alt="GOPROTEXT" className="IMAGETEXT2" />
        </div>
        <div className="Image-Text-Container2">
          <img
            src={PowerVisionDrone}
            alt="POWERVISIONIMAGE"
            className="IMAGE2"
          />
          <img
            src={PowerVisionText}
            alt="POWERVISIONTEXT"
            className="IMAGETEXT2"
          />
        </div>
        <div className="Image-Text-Container2">
          <img src={UbsanDrone} alt="UBSANDRONEIMAGE" className="IMAGE2" />
          <img src={UbsanText} alt="UBSANTEXT" className="IMAGETEXT2" />
        </div>
      </div>
      <div className="Bottom-Image">
        <img src={BottomImage} alt="BottomImage" className="Bottom-Img" />
      </div>
    </div>
  );
}

export default BrandingList;
