import React from "react";
import { useLocation } from "react-router-dom";
import "./cancel_policy.css";

type LocationState = {
  selectedDateRange: {
    endDate: string;
  };
};

function CancelPolicy() {
  const location = useLocation<LocationState>();
  const { selectedDateRange } = location.state;

  const date = new Date(selectedDateRange.endDate);
  const formattedEndDate = `${date.getDate()} ${date.toLocaleString("en-EN", {
    month: "long",
  })}`;

  return (
    <div className="cancel-policy-section">
      <div className="cancel-policy-text">
        <h3>Cancel Policy</h3>
        <p>
          <b>You can cancel in the first 48 hours.</b> If you cancel before{" "}
          {formattedEndDate}, you will get a partial refund.{" "}
          <a href="">Learn more.</a>
        </p>
      </div>
    </div>
  );
}

export default CancelPolicy;
