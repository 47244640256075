// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-policy-section {
    box-sizing: border-box;
    margin: 50px 0;
}`, "",{"version":3,"sources":["webpack://./src/checkout/components/cancel_policy/cancel_policy.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".cancel-policy-section {\n    box-sizing: border-box;\n    margin: 50px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
