// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-modal-content {
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 20px;
  border-radius: 20px;
  width: auto;
  max-width: 433px;
}

.login-modal-content h2 {
  padding: 0;
  margin: 0;
  font-size: 1em;
}

.join-us-txt {
  text-align: center;
  padding: 0;
  margin:0;
}

.login-modal-content hr {
  margin: 10px 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/common/accounts/login/login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+CAA+C;EAC/C,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".login-modal-content {\n  position: relative;\n  background: #FFFFFF 0% 0% no-repeat padding-box;\n  padding: 20px;\n  border-radius: 20px;\n  width: auto;\n  max-width: 433px;\n}\n\n.login-modal-content h2 {\n  padding: 0;\n  margin: 0;\n  font-size: 1em;\n}\n\n.join-us-txt {\n  text-align: center;\n  padding: 0;\n  margin:0;\n}\n\n.login-modal-content hr {\n  margin: 10px 0 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
