// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-drone-footer {
  margin-top: auto;
}

.edit-drone-footer-wrapper {
  box-sizing: border-box;
  width: 100%;
  background-color: #232940;
  padding: 27px 52px 35px 27px;
  border-radius: 20px 20px 0px 0px;
}


.footer-progress-bar {
  display: flex;
  gap: 33px;
  width: 100%;
  justify-content: space-between;
}


.line-bar {
  width: 25%;
  height: 17px;
  background-color: grey;
  position: relative;
  border-radius: 9px;
}`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/edit_drone_footer/edit_drone_footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,yBAAyB;EACzB,4BAA4B;EAC5B,gCAAgC;AAClC;;;AAGA;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,8BAA8B;AAChC;;;AAGA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".edit-drone-footer {\n  margin-top: auto;\n}\n\n.edit-drone-footer-wrapper {\n  box-sizing: border-box;\n  width: 100%;\n  background-color: #232940;\n  padding: 27px 52px 35px 27px;\n  border-radius: 20px 20px 0px 0px;\n}\n\n\n.footer-progress-bar {\n  display: flex;\n  gap: 33px;\n  width: 100%;\n  justify-content: space-between;\n}\n\n\n.line-bar {\n  width: 25%;\n  height: 17px;\n  background-color: grey;\n  position: relative;\n  border-radius: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
