import React, { useContext, useState, useEffect } from "react";
import "./location_step.css";

import { StepperContext } from "../stepper/stepper_context";
import AsyncSelect from "react-select/async";
import GoogleMapsContainer from "../../../common/googlemaps/AppWithMap";
import axios from "axios";
import { GroupBase, SingleValue } from "react-select";

type LocationType = {
  value: string;
  label: string;
  lat?: number;
  lng?: number;
};

const LocationStep = () => {
  const context = useContext(StepperContext);
  const { droneData, setDroneData, saveDroneChanges, droneId } =
    useContext(StepperContext)!;
  type OptionsOrGroups = OptionType | GroupBase<OptionType>;
  type OptionType = { value: string; label: string };
  type GroupTypeBase<OptionType> = GroupBase<OptionType>;
  type OptionTypeBase = { label: string; value: string };
  const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(
    null
  );
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const loadOptions = (
    inputText: string,
    callback: (options: OptionsOrGroups[]) => void
  ) => {
    axios
      .get(`https://staging.droneden.ro:8080/api/maps/autocomplete?input=${inputText}`)

      .then((response) => {
        const results: OptionsOrGroups[] = response.data.predictions.map(
          (prediction: any) => ({
            value: prediction.place_id,
            label: prediction.description,
          })
        );
        callback(results);
      })
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  async function getGeoCoordinates(address: string) {
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Folosește cheia din mediul tău
          },
        }
      );

      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error("Nu s-au găsit coordonate pentru adresa dată.");
      }
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Eroare la obținerea coordonatelor:", error.message);
      } else {
        console.error("Eroare necunoscută:", error);
      }
      return { lat: 0, lng: 0 }; // În caz de eroare, returnează coordonate implicite
    }
  }

  function handleLocationChange(
    newValue: SingleValue<OptionTypeBase | GroupTypeBase<OptionTypeBase>>,
    actionMeta: any
  ) {
    if (newValue && "value" in newValue && "label" in newValue) {
      const selectedOption = { value: newValue.value, label: newValue.label };

      getGeoCoordinates(selectedOption.label).then((coords) => {
        setSelectedLocation({ ...selectedOption, ...coords });
      });

      setDroneData((prevDetails) => {
        if (!prevDetails) {
          return null;
        }
        return {
          ...prevDetails,
          location: selectedOption.label,
        };
      });

      setIsLocationSelected(true); // Setăm starea ca fiind adevărată când o locație este selectată
    } else {
      setSelectedLocation(null);

      setDroneData((prevDetails) => {
        if (!prevDetails) {
          return null;
        }
        return {
          ...prevDetails,
          location: null,
        };
      });

      setIsLocationSelected(false); // Resetați starea când locația este ștearsă
    }
  }

  useEffect(() => {
    if (droneData && droneId) {
      const { lat, lng, location } = droneData;

      // Setăm starea locală cu datele din context
      setSelectedLocation({
        value: location || "",
        label: location || "",
        lat: lat,
        lng: lng,
      });

      setIsLocationSelected(location ? true : false);
    }
  }, [droneData, droneId]);

  if (!droneData) {
    return <div>Context is not available</div>;
  }

  return (
    <div className="location-step-wrapper">
      <div className="location-step-top-text">
        <h2>Location</h2>
        <p>Choose an aproximate location where you can rent the drone</p>
      </div>
      <div className="input-and-maps">
        <AsyncSelect
          className="location-input-step"
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleLocationChange}
          value={selectedLocation} // Setăm valoarea pentru a fi cea selectată
          styles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              display: "none",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            control: (provided) => ({
              ...provided,
              border: "1px solid #232940",
              borderRadius: "8px",
              fontSize: "1.125em",
              padding: "15px 60px",
              marginBottom: "11px",
            }),
          }}
        />

        <GoogleMapsContainer
          droneLocation={
            selectedLocation
              ? { lat: selectedLocation.lat!, lng: selectedLocation.lng! }
              : null
          }
          onLocationChange={(newLocation) => {
            // Obțineți numele orașului folosind serviciul de geocodare inversă de la Google Maps API
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ location: newLocation }, (results, status) => {
              if (status === "OK") {
                if (results && results[0]) {
                  const addressComponents = results[0].address_components;
                  const cityNameComponent = addressComponents?.find(
                    (component) => component.types.includes("locality")
                  );

                  const cityName = cityNameComponent
                    ? cityNameComponent.long_name
                    : results[0].formatted_address;

                  // Setează valoarea cu numele și coordonatele locației obținute
                  setSelectedLocation({
                    ...selectedLocation,
                    lat: newLocation.lat,
                    lng: newLocation.lng,
                    value: cityName,
                    label: cityName,
                  });

                  // Actualizează și droneData pentru a include noile coordonate
                  setDroneData((prevDetails) => {
                    if (!prevDetails) {
                      return null;
                    }
                    return {
                      ...prevDetails,
                      location: cityName,
                      lat: newLocation.lat,
                      lng: newLocation.lng,
                    };
                  });
                } else {
                  window.alert("No results found");
                }
              } else {
                window.alert("Geocoder failed due to: " + status);
              }
            });
          }}
        />
      </div>
    </div>
  );
};

export default LocationStep;
