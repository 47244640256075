import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import "./name_drone.css";

import { StepperContext } from "../stepper/stepper_context";

const NameDrone = () => {
  const context = useContext(StepperContext);
  const [localName, setLocalName] = useState("");
  const { setDroneData, droneData } = useContext(StepperContext)!;
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");

  const brandsAndModels: { [key: string]: string[] } = {
    "": [],
    DJI: [
      "Phantom 4",
      "Phantom 4 Pro",
      "Phantom 4 Advanced",
      "Mavic Pro",
      "Mavic Air",
      "Mavic Air 2",
      "Mavic 2 Pro",
      "Mavic 2 Zoom",
      "Mavic Mini",
      "Mavic Mini 2",
      "Inspire 1",
      "Inspire 2",
      "Spark",
      "FPV Drone",
      "Air 2S",
      "Mini SE",
    ],
    Parrot: [
      "Anafi",
      "Anafi USA",
      "Anafi Thermal",
      "Bebop 2",
      "Bebop 2 Power",
      "Disco",
      "Disco AG",
      "Bluegrass",
      "Bluegrass Fields",
      "Ar.Drone 2.0",
    ],
    Autel: [
      "EVO Lite",
      "EVO Lite+",
      "EVO Lite Premium",
      "EVO Nano",
      "EVO Nano+",
      "EVO II",
      "EVO II Pro",
      "EVO II Dual",
      "Dragonfish",
    ],
    Yuneec: [
      "Typhoon H Pro",
      "Typhoon H3",
      "Typhoon Q500 4K",
      "Mantis G",
      "Mantis Q",
      "Breeze",
      "Tornado H920",
    ],
    Skydio: ["Skydio 2", "Skydio X2", "Skydio R1"],
    SenseFly: [
      "eBee X",
      "eBee Geo",
      "eBee TAC",
      // ... (și multe alte modele)
    ],
    // ... (și multe alte branduri și modele)
  };

  const handleBrandChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newBrand = event.target.value;
    setBrand(newBrand);
    setModel("");

    setDroneData((prevDetails) => {
      if (prevDetails === null) {
        return null;
      }
      return {
        ...prevDetails,
        brand: newBrand,
      };
    });
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newModel = event.target.value;
    setModel(newModel);

    setDroneData((prevDetails) => {
      if (prevDetails === null) {
        return null;
      }
      return {
        ...prevDetails,
        model: newModel,
      };
    });
  };

  useEffect(() => {
    if (context && context.droneData) {
      setLocalName(context.droneData.name);
      setBrand(context.droneData.brand || "");
      setModel(context.droneData.model || "");
    }
  }, [context]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setLocalName(newName);

    if (context && context.setDroneData && context.droneData) {
      const updatedDroneData = {
        ...context.droneData,
        name: newName,
      };
      context.setDroneData(updatedDroneData);
    }
  };

  if (context === null) {
    return <div>Context is not available</div>;
  }

  return (
    <div className="name-drone-wrapper">
      <div className="naming-section">
        <h2>Let's name your advertisement</h2>
        <p>Short and specific name will give the best result</p>
        <input
          className="name-drone-input"
          type="text"
          placeholder="Example: Performing cartography drone services"
          value={localName}
          onChange={handleNameChange}
        />
      </div>
      <div className="brand-and-model-changes">
        <h2>Let's choose the brand and model</h2>
        <select
          className="brand-selection-change"
          value={brand}
          onChange={handleBrandChange}
        >
          <option value="" disabled>
            Selectează un brand
          </option>
          <option value="DJI">DJI</option>
          <option value="Parrot">Parrot</option>
          {/* Adaugă alte opțiuni de brand aici */}
        </select>

        <select
          value={model}
          onChange={handleModelChange}
          disabled={!brand}
          className="model-selection-input-change"
        >
          <option value="" disabled>
            Selectează un model
          </option>
          {brandsAndModels[brand].map((modelOption: string, index: number) => (
            <option key={index} value={modelOption}>
              {modelOption}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default NameDrone;
