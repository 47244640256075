import React from "react";
import "./drone_mate_header.css";

import DroneMateIcon from "../../assets/DroneMate.png";

const DroneMateHeader = () => {
  return (
    <div className="drone-mate-header">
      <h2>DroneMate</h2>
      <div className="drone-mate-header-icon">
        <img
          src={DroneMateIcon}
          alt="Drone Mate Icon"
          className="drone-mate-header-icon"
        />
      </div>
    </div>
  );
};

export default DroneMateHeader;
