import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./payment_method.css";

type LocationState = {
  droneData: {
    imageUrls: string[];
    name: string;
    location: string;
    price: number;
  };
};

function PaymentMethod() {
  const [selectedOption, setSelectedOption] = useState("full");
  const location = useLocation<LocationState>();
  const { droneData } = location.state;

  return (
    <div className="payment-method-section">
      <div
        className={`option ${selectedOption === "full" ? "selected" : ""}`}
        onClick={() => setSelectedOption("full")}
      >
        <div className="payment-method-radio-button"></div>
        <div className="payment-description">
          Plătește suma totală {droneData.price} euro acum și ai terminat.
        </div>
      </div>
      <div
        className={`option ${selectedOption === "split" ? "selected" : ""}`}
        onClick={() => setSelectedOption("split")}
      >
        <div className="payment-method-radio-button"></div>
        <div className="payment-description">
          Plătește o parte acum, o parte mai târziu.
          <br />
          {(droneData.price / 2).toFixed(2)} euro de achitat azi,
          <br />
          {(droneData.price / 2).toFixed(2)} euro de achitat pe data de
          xx.xx.xxxx.
          <br />
          Fără taxe suplimentare.
          <br />
          Informații suplimentare
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
