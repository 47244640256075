import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import "./account_settings_tab.css";
import axios from "axios";

interface AccountSettingsTabProps {
  handleLogout: () => void;
}

const AccountSettingsTab: React.FC<AccountSettingsTabProps> = ({
  handleLogout,
}) => {
  const { userData, setUserData } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName || "");
      setLastName(userData.lastName || "");
      setEmail(userData.email || "");
      setPhoneNumber(userData.phoneNumber || "");
    }
  }, [userData]);

  const handleDeleteAccount = () => {
    setShowDeleteConfirmation(true);
  };

  const handleSaveChanges = async () => {
    try {
      if (userData) {
        const updatedUser = {
          ...userData,
          firstName,
          lastName,
          email,
          phoneNumber,
        };
        const response = await axios.put(
          `https://staging.droneden.ro:8080/api/users/${userData.id}`,
          updatedUser
        );
        if (response.status === 200) {
          setUserData(updatedUser);
        }
        setIsEditing(false);
      } else {
        throw new Error("Error loading user data.");
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const handleCancel = () => {
    if (userData) {
      setFirstName(userData.firstName || "");
      setLastName(userData.lastName || "");
      setEmail(userData.email || "");
      setPhoneNumber(userData.phoneNumber || "");
    }
    setIsEditing(false);
  };

  const confirmDeleteAccount = async () => {
    try {
      if (userData) {
        const response = await axios.delete(
          `https://staging.droneden.ro:8080/api/users/${userData?.id}`
        );
        if (response.status === 200) {
          handleLogout();
        }
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  return (
    <main className="main-content-account">
      <button className="edit-btn" onClick={() => setIsEditing(true)}>
        Edit
      </button>
      {isEditing ? (
        <>
          <div className="input-group">
            <label>First Name: </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Last Name: </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Email: </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Phone Number: </label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="edit-buttons">
            <button className="save-changes-btn" onClick={handleSaveChanges}>
              Save Changes
            </button>
            <button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="details-group">
            <label>First Name: </label>
            <p>{firstName}</p>
          </div>
          <div className="details-group">
            <label>Last Name: </label>
            <p>{lastName}</p>
          </div>
          <div className="details-group">
            <label>Email: </label>
            <p>{email}</p>
          </div>
          <div className="details-group">
            <label>Phone Number: </label>
            <p>{phoneNumber}</p>
          </div>
        </>
      )}
      <hr />
      <div className="account-settings-buttons">
        <button className="account-forgot-pass-btn">Forgot Password</button>
        <button className="delete-account-btn" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>
      {showDeleteConfirmation && (
        <div className="delete-confirmation">
          <p>Are you sure you want to delete your account?</p>
          <button className="yes-btn" onClick={confirmDeleteAccount}>
            Yes
          </button>
          <button
            className="no-btn"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            No
          </button>
        </div>
      )}
    </main>
  );
};

export default AccountSettingsTab;
