import React, { createContext, useState, useCallback, ReactNode } from "react";
import axios from "axios";

export type User = {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
};

type DroneData = {
  id: number;
  imageUrls: string[];
  name: string;
  postedBy: User;
  dateTime?: string;
  price: number | null;
  description: string;
  specs: { [key: string]: string };
  location: string | null;
  lat?: number;
  lng?: number;
  extraDetails: string;
  categories: string[];
  category?: string[];
  brand?: string;
  model?: string;
  experience?: string;
  extraGears?: string[];
};

interface StepperContextProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  droneData: DroneData | null;
  setDroneData: React.Dispatch<React.SetStateAction<DroneData | null>>;
  fetchDroneDetails: (id: number) => Promise<void>;
  saveDroneChanges: (droneData: DroneData) => Promise<void>;
  droneId: number | null;
  setDroneId: React.Dispatch<React.SetStateAction<number | null>>;
  updateDroneCategory: (newCategoryArray: string[]) => void;
  newDroneData: DroneData | null;
  updateNewDroneData: (updatedData: Partial<DroneData>) => void;
  updateCategoryBooleanArray: (newCategoryBoolean: boolean[]) => void;
  imageUrls: string[];
  fetchImageUrls: (imageFileNames: string[]) => Promise<string[]>;
  uploadedImages: string[];
  uploadImagesToServer: (imageFiles: File[]) => Promise<void>;
  imageFiles: File[];
  setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
  existingImages: string[];
  setExistingImages: React.Dispatch<React.SetStateAction<string[]>>;
}

interface StepperProviderProps {
  children: ReactNode;
}

export const StepperContext = createContext<StepperContextProps | null>(null);

export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [droneData, setDroneData] = useState<DroneData | null>(null);
  const [droneId, setDroneId] = useState<number | null>(null);
  const [newDroneData, setNewDroneData] = useState<DroneData | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<string[]>([]);

  const fetchDroneDetails = useCallback(async (id: number) => {
    try {
      const response = await axios.get(
        `https://staging.droneden.ro:8080/api/drones/${id}`
      );
      if (response.status === 200) {
        setDroneData(response.data);
        fetchImageUrls(response.data.imageUrls).then((urls) => {
          setExistingImages(urls);
        });
      }
    } catch (error) {
      console.error("Error fetching drone details:", error);
    }
  }, []);

  const updateNewDroneData = (updatedData: Partial<DroneData>) => {
    setNewDroneData((prevData) => {
      if (prevData === null) {
        return null;
      }
      return {
        ...prevData,
        ...updatedData,
      };
    });
  };
  const uploadImagesToServer = async (imageFiles: File[]) => {
    const formData = new FormData();
    imageFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });
    try {
      const response = await axios.post(
        "https://staging.droneden.ro:8080/file/upload",
        formData
      );
      if (response.status === 200) {
        setUploadedImages(response.data);
        return response.data;
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("An error occurred while uploading images.");
    }
    return null;
  };

  const saveDroneChanges = async (updatedDroneData: DroneData) => {
    try {
      const newImageUrls = await uploadImagesToServer(imageFiles);

      if (newImageUrls && newImageUrls.length > 0) {
        updatedDroneData = {
          ...updatedDroneData,
          imageUrls: newImageUrls,
        };
      }
      console.log(
        "Sending the following data to the server:",
        updatedDroneData
      );
      const response = await axios.put(
        `https://staging.droneden.ro:8080/api/drones/${updatedDroneData.id}`,
        updatedDroneData
      );

      if (response.status === 200) {
        console.log("Data successfully updated");
        console.log("Updated drone data:", updatedDroneData);
        alert("Changes saved successfully!");
      } else {
        console.log("Failed to save changes");
        alert("Failed to save changes.");
      }
    } catch (error) {
      console.error("Error updating drone details:", error);
      alert("An error occurred while saving changes.");
    }
  };

  const updateDroneCategory = async (newCategoryArray: string[]) => {
    if (droneData) {
      const updatedDroneData: DroneData = {
        ...droneData,
        category: newCategoryArray,
      };
      setDroneData(updatedDroneData);

      await saveDroneChanges(updatedDroneData);
    }
  };

  const updateCategoryBooleanArray = (newCategoryBoolean: boolean[]) => {
    if (droneData) {
      const newCategoryArray = newCategoryBoolean.map((bool) =>
        bool.toString()
      );
      const updatedDroneData: DroneData = {
        ...droneData,
        category: newCategoryArray,
      };
      setDroneData(updatedDroneData);
      console.log("Updated droneData in context:", updatedDroneData);
    }
  };

  const fetchImageUrls = async (
    imageFileNames: string[]
  ): Promise<string[]> => {
    try {
      const fetchedImageUrls = await Promise.all(
        imageFileNames.map(async (fileName) => {
          const response = await fetch(
            `https://staging.droneden.ro:8080/file/download?fileName=${fileName}`
          );
          if (response.ok) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          } else {
            console.error("Failed to fetch image:", fileName);
            return "";
          }
        })
      );
      return fetchedImageUrls;
    } catch (error) {
      console.error("Failed to fetch images:", error);
      return [];
    }
  };

  return (
    <StepperContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        droneData,
        setDroneData,
        fetchDroneDetails,
        saveDroneChanges,
        droneId,
        setDroneId,
        updateDroneCategory,
        newDroneData,
        updateNewDroneData,
        updateCategoryBooleanArray,
        imageUrls,
        fetchImageUrls,
        uploadedImages,
        uploadImagesToServer,
        imageFiles,
        setImageFiles,
        existingImages,
        setExistingImages,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
