import React from "react";

interface TabNavigationProps {
  activeTab: string;
  handleTabClick: (tabName: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <nav className="account-tabs">
      <ul>
        <li
          onClick={() => handleTabClick("Account Settings")}
          className={activeTab === "Account Settings" ? "active" : ""}
        >
          Account Settings
        </li>
        <li
          onClick={() => handleTabClick("Messages")}
          className={activeTab === "Messages" ? "active" : ""}
        >
          Messages
        </li>
        <li
          onClick={() => handleTabClick("My Listings")}
          className={activeTab === "My Listings" ? "active" : ""}
        >
          My Listings
        </li>
      </ul>
    </nav>
  );
};

export default TabNavigation;
