import React, { useState, useEffect, useContext } from "react";
import "./category_card.css";

import EventsIcon from "../../../assets/images/events-icon.png";
import BuildingIcon from "../../../assets/images/building-icon.png";
import CommercialIcon from "../../../assets/images/comercial-icon.png";
import JournalismIcon from "../../../assets/images/journalism-icon.png";
import SportsIcon from "../../../assets/images/sports-icon.png";
import AgricultureIcon from "../../../assets/images/agriculture-icon.png";
import CameraIcon from "../../../assets/images/camera-icon.png";
import DroneIcon from "../../../assets/images/drone-icon.png";
import InsuranceIcon from "../../../assets/images/insurances-icon.png";

import { StepperContext } from "../../stepper/stepper_context";

// Componenta pentru o singura card de categorie
interface CategoryCardProps {
  icon: any; // înlocuiți cu tipul adecvat pentru iconițe
  label: string;
  isSelected: boolean;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  icon,
  label,
  isSelected,
}) => {
  return (
    <div
      className={`category-card ${isSelected ? "category-card-selected" : ""}`}
    >
      <img src={icon} alt={label} />
      <p className="category-card-text">{label}</p>
    </div>
  );
};
// Componenta principala pentru toate cardurile de categorie
export const CategoryCards: React.FC = () => {
  const stepperContext = useContext(StepperContext);
  // Lista de categorii și iconițe
  const categories = [
    { icon: AgricultureIcon, label: "Agriculture" },
    { icon: CommercialIcon, label: "Cartography" },
    { icon: CommercialIcon, label: "Commercial" },
    { icon: BuildingIcon, label: "Construction" },
    { icon: CameraIcon, label: "Energy" },
    { icon: CameraIcon, label: "Energy/Solar Panels/Wind turbines" },
    { icon: BuildingIcon, label: "Real Estate" },
    { icon: JournalismIcon, label: "Journalism/Cinematography" },
    { icon: SportsIcon, label: "Extreme Sports" },
    { icon: DroneIcon, label: "Drone Light Shows" },
    { icon: InsuranceIcon, label: "Insurances" },
    { icon: BuildingIcon, label: "Nature Conservation" },
  ];

  const [categoryBoolean, setCategoryBoolean] = useState(
    new Array(categories.length).fill(false)
  );

  const toggleCategory = (index: number) => {
    const newCategoryBoolean = [...categoryBoolean];
    newCategoryBoolean[index] = !newCategoryBoolean[index];
    setCategoryBoolean(newCategoryBoolean);

    console.log("Updated categoryBoolean:", newCategoryBoolean);

    if (stepperContext?.updateCategoryBooleanArray) {
      stepperContext.updateCategoryBooleanArray(newCategoryBoolean);
      console.log("Called updateCategoryBooleanArray in context");
    }
  };

  useEffect(() => {
    if (stepperContext?.droneData?.category) {
      const initialCategoryBoolean = stepperContext.droneData.category.map(
        (str) => str === "true"
      );
      setCategoryBoolean(initialCategoryBoolean);
      console.log("Initial categoryBoolean:", initialCategoryBoolean); // Log aici
    }
  }, [stepperContext?.droneData]);

  return (
    <div className="category-cards">
      {categories.map((category, index) => (
        <div
          key={index}
          onClick={() => toggleCategory(index)} // Actualizează starea când se face click pe card
        >
          <CategoryCard
            icon={category.icon}
            label={category.label}
            isSelected={categoryBoolean[index]}
          />
        </div>
      ))}
    </div>
  );
};
export default CategoryCards;
