// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./common/fonts/Ubuntu-R.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./common/fonts/Ubuntu-LI.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./common/fonts/Ubuntu-M.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./common/fonts/Ubuntu-MI.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./common/fonts/Ubuntu-B.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./common/fonts/Ubuntu-BI.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./common/fonts/Ubuntu-L.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Ubuntu Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Ubuntu Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Ubuntu Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu Medium Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu Bold Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Ubuntu Light Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-style: italic;
  font-weight: 300;
}

body {
  margin: 0 auto;
  font-family: 'Ubuntu Regular', sans-serif;
  font-weight: 400;
}

.homepage-container {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4CAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,4CAAwC;EACxC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,4CAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;EACnC,4CAAwC;EACxC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,4CAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,4CAAwC;EACxC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4CAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,4CAAwC;EACxC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Ubuntu Regular';\n  src: url('./common/fonts/Ubuntu-R.ttf');\n  font-style: normal;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'Ubuntu Italic';\n  src: url('./common/fonts/Ubuntu-LI.ttf');\n  font-style: italic;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'Ubuntu Medium';\n  src: url('./common/fonts/Ubuntu-M.ttf');\n  font-style: normal;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'Ubuntu Medium Italic';\n  src: url('./common/fonts/Ubuntu-MI.ttf');\n  font-style: italic;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'Ubuntu Bold';\n  src: url('./common/fonts/Ubuntu-B.ttf');\n  font-style: normal;\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: 'Ubuntu Bold Italic';\n  src: url('./common/fonts/Ubuntu-BI.ttf');\n  font-style: italic;\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: 'Ubuntu Light';\n  src: url('./common/fonts/Ubuntu-L.ttf');\n  font-style: normal;\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'Ubuntu Light Italic';\n  src: url('./common/fonts/Ubuntu-LI.ttf');\n  font-style: italic;\n  font-weight: 300;\n}\n\nbody {\n  margin: 0 auto;\n  font-family: 'Ubuntu Regular', sans-serif;\n  font-weight: 400;\n}\n\n.homepage-container {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
