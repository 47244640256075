import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import AsyncSelect from "react-select/async";
import "./RentOutDrones.css";

import DroneDenLogoImageWizard from "./assets/images/droneden-header-image-logo.png";
import EventsIcon from "./assets/images/events-icon.png";
import BuildingIcon from "./assets/images/building-icon.png";
import CommercialIcon from "./assets/images/comercial-icon.png";
import JournalismIcon from "./assets/images/journalism-icon.png";
import SportsIcon from "./assets/images/sports-icon.png";
import AgricultureIcon from "./assets/images/agriculture-icon.png";
import CameraIcon from "./assets/images/camera-icon.png";
import DroneIcon from "./assets/images/drone-icon.png";
import InsuranceIcon from "./assets/images/insurances-icon.png";
import TrashCanIcon from "./assets/images/trashcan.png";
import EditPhotoIcon from "./assets/images/edit-photo.png";

import { GroupBase, SingleValue } from "react-select";

import GoogleMapsContainer from "../common/googlemaps/AppWithMap";

function RentOutDrones() {
  const [extraDetails, setExtraDetails] = useState("");
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const imagesFormData = new FormData();

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (currentStep === 4) {
      const incompleteSpecs = droneSpecs.filter(
        (spec) => spec.spec && !spec.value
      );
      if (incompleteSpecs.length > 0) {
        alert(
          "Please provide values for all selected specs or remove the empty ones."
        );

        return;
      }
    }

    // La pasul 7, ne asigurăm că a fost selectată o locație
    if (currentStep === 7 && !isLocationSelected) {
      alert("Please select or enter a city.");
      return;
    }

    if (currentStep === 9 && (!extraDetails || extraDetails.trim() === "")) {
      alert("Please enter additional details.");
      return;
    }

    let canProceed = true;

    if (currentStep === totalSteps - 1) {
      // Construieste obiectul drone cu datele din state-uri
      const droneData = {
        name: inputValue,
        price,
        postedBy: null, // Adăugați un câmp în formular pentru a prelua utilizatorul care a postat
        specs: Object.fromEntries(
          droneSpecs.map((spec) => [spec.spec, spec.value])
        ),
        description: droneDetails.experience || "",
        category: selectedCategories, // Adăugați un câmp în formular pentru a prelua categoria
        model,
        brand,
        imageUrls: images,
        extraGears,
        dateTime: new Date().toISOString().split(".")[0],
        location: selectedLocation?.label || "",
        lat: selectedLocation?.lat,
        lng: selectedLocation?.lng,
        extraDetails,
      };

      // Fetch JWT token from local storage
      const token = localStorage.getItem("jwt_token");

      images.forEach((imageBase64, index) => {
        const blob = base64ToBlob(imageBase64, "image/jpeg");
        imagesFormData.append("files", blob, `image-${index}.jpg`);
      });

      // Verificăm dacă coverPhoto este null înainte de a-l converti în Blob
      // Verificăm dacă coverPhoto este null înainte de a-l converti în Blob
      if (coverPhoto) {
        const coverPhotoBlob = base64ToBlob(coverPhoto, "image/jpeg"); // Convertim coverPhoto în Blob
        imagesFormData.append("coverPhoto", coverPhotoBlob, "coverPhoto.jpg");
      }

      // Trimite imaginile la server
      axios
        .post("https://staging.droneden.ro:8080/file/upload", imagesFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token, // Add this line
          },
        })
        .then((response) => {
          console.log("Imaginile au fost încărcate cu succes", response.data);
          droneData.imageUrls = response.data; // Adaugă numele fișierelor încărcate în droneData

          // Trimite requestul POST pentru a crea o noua drona
          axios
            .post("https://staging.droneden.ro:8080/api/drones", droneData, {
              headers: {
                Authorization: "Bearer " + token, // Add this line
              },
            })
            .then((response) => {
              alert("Drone created successfully!");
              console.log("Drone created:", response.data);
              window.location.href = "https://staging.droneden.ro:8080"; // Redirecționează către 20.50.136.120:3000
            })
            .catch((error) => {
              console.error(
                "Error creating drone:",
                error.response?.data || error.message
              );
              alert("Error creating drone. Please try again.");
            });
        })
        .catch((error) => {
          console.error("Eroare la încărcarea imaginilor", error);
        });
    }

    if (currentStep === 1 && !selectedCategories.includes(true)) {
      canProceed = false;
      alert("Te rog selectează cel puțin o categorie.");
    }

    if (
      currentStep === 2 &&
      (inputValue === "" || brand === "" || model === "")
    ) {
      canProceed = false;
      alert("Te rog completează toate câmpurile înainte de a continua.");
    }

    if (
      currentStep === 3 &&
      (!droneDetails.experience || droneDetails.experience.trim() === "")
    ) {
      canProceed = false;
      alert("Te rog oferă detalii despre experiența ta înainte de a continua.");
    }

    if (canProceed) {
      if (currentStep === 4) {
        setDroneDetails((prevDetails) => ({
          ...prevDetails,
          specs: droneSpecs.filter((spec) => spec.spec && spec.value),
        }));
      }
      if (currentStep === 5) {
        setDroneDetails((prevDetails) => ({
          ...prevDetails,
          extraGears,
        }));
      }
      setCurrentStep((prevStep) => Math.min(prevStep + 1, totalSteps));
    }
  };

  const [currentActiveMiniature, setCurrentActiveMiniature] = useState<
    number | null
  >(null);

  const handleCoverPhotoUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const coverPhotoData = reader.result as string;
        setCoverPhoto(coverPhotoData);
        setImages([
          coverPhotoData,
          ...images.filter((_, index) => index !== 0),
        ]); // Adaugă coverPhoto la începutul array-ului images
        setCurrentActiveMiniature(1); // Activăm prima miniatură după încărcarea pozei de cover
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (index: number) => {
    if (index === currentActiveMiniature || (index === 0 && coverPhoto)) {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.onchange = (e) => {
        const file = (e.target as HTMLInputElement).files![0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const newImages = [...images];
            newImages[index] = reader.result as string;
            setImages(newImages);
            setCurrentActiveMiniature(index + 1); // Activăm următoarea miniatură după încărcarea unei imagini în miniatura curentă
          };
          reader.readAsDataURL(file);
        }
      };
      fileInput.click();
    }
  };

  const handleImageDelete = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, idx) => idx !== index));
    setCurrentActiveMiniature((prev) => (prev !== null ? prev - 1 : null));
  };

  const handleCoverPhotoDelete = () => {
    setCoverPhoto(null);
  };

  const [images, setImages] = useState<string[]>([]);

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const categories = [
    { icon: AgricultureIcon, label: "Agriculture" },
    { icon: CommercialIcon, label: "Cartography" },
    { icon: CommercialIcon, label: "Commercial" },
    { icon: BuildingIcon, label: "Construction" },
    { icon: CameraIcon, label: "Energy" },
    { icon: CameraIcon, label: "Energy/Solar Panels/Wind turbines" },
    { icon: BuildingIcon, label: "Real Estate" },
    { icon: JournalismIcon, label: "Journalism/Cinematography" },
    { icon: SportsIcon, label: "Extreme Sports" },
    { icon: DroneIcon, label: "Drone Light Shows" },
    { icon: InsuranceIcon, label: "Insurances" },
    { icon: BuildingIcon, label: "Nature Conservation" },
  ];

  const [selectedCategories, setSelectedCategories] = useState(
    Array(categories.length).fill(false)
  );

  type DroneDetails = {
    categories: string[];
    brand?: string;
    model?: string;
    experience?: string;
    specs?: { spec: string; value: string }[];
    extraGears?: string[];
    hourlyRate?: number;
    editingCost?: number;
    travelCostPerKm?: number;
    location?: string | null;
  };

  const [droneDetails, setDroneDetails] = useState<DroneDetails>({
    categories: [],
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  type OptionTypeBase = { label: string; value: string };
  type GroupTypeBase<OptionType> = GroupBase<OptionType>;

  type OptionType = { value: string; label: string };
  type OptionsOrGroups = OptionType | GroupBase<OptionType>;

  const loadOptions = (
    inputText: string,
    callback: (options: OptionsOrGroups[]) => void
  ) => {
    axios
      .get(`https://staging.droneden.ro:8080/api/maps/autocomplete?input=${inputText}`)

      .then((response) => {
        const results: OptionsOrGroups[] = response.data.predictions.map(
          (prediction: any) => ({
            value: prediction.place_id,
            label: prediction.description,
          })
        );
        callback(results);
      })
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  async function getGeoCoordinates(address: string) {
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Folosește cheia din mediul tău
          },
        }
      );

      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error("Nu s-au găsit coordonate pentru adresa dată.");
      }
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Eroare la obținerea coordonatelor:", error.message);
      } else {
        console.error("Eroare necunoscută:", error);
      }
      return { lat: 0, lng: 0 }; // În caz de eroare, returnează coordonate implicite
    }
  }

  function handleLocationChange(
    newValue: SingleValue<OptionTypeBase | GroupTypeBase<OptionTypeBase>>,
    actionMeta: any
  ) {
    if (newValue && "value" in newValue && "label" in newValue) {
      const selectedOption = { value: newValue.value, label: newValue.label };

      getGeoCoordinates(selectedOption.label).then((coords) => {
        setSelectedLocation({ ...selectedOption, ...coords });
      });

      setDroneDetails((prevDetails) => ({
        ...prevDetails,
        location: selectedOption.label,
      }));

      setIsLocationSelected(true); // Setăm starea ca fiind adevărată când o locație este selectată
    } else {
      setSelectedLocation(null);

      setDroneDetails((prevDetails) => ({
        ...prevDetails,
        location: null,
      }));

      setIsLocationSelected(false); // Resetați starea când locația este ștearsă
    }
  }

  const handleCategoryChange = (index: number) => {
    console.log("Handling change for index:", index); // Debugging line

    const newSelectedCategories = [...selectedCategories];
    newSelectedCategories[index] = !newSelectedCategories[index];
    setSelectedCategories(newSelectedCategories);

    console.log("New Selected Categories:", newSelectedCategories); // Debugging line

    // Get the labels of the selected categories
    const selectedCategoriesLabels = categories
      .filter((_, idx) => newSelectedCategories[idx])
      .map((category) => category.label);

    console.log("Selected Categories Labels:", selectedCategoriesLabels); // Debugging line

    setDroneDetails((prevDetails) => ({
      ...prevDetails,
      categories: selectedCategoriesLabels,
    }));
  };

  const [droneSpecs, setDroneSpecs] = useState([{ spec: "", value: "" }]);

  const specsOptions = [
    "Camera",
    "Max Flight Time",
    "Max Speed",
    "Transmission Range",
    // Adaugă alte opțiuni aici
  ];

  const handleSpecChange = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newDroneSpecs = [...droneSpecs];
    newDroneSpecs[index].spec = event.target.value;
    setDroneSpecs(newDroneSpecs);

    // Dacă toate dropdown-urile au o valoare selectată, adaugă un nou rând
    if (newDroneSpecs.every((spec) => spec.spec)) {
      addNewSpec();
    }
  };

  const handleSpecValueChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDroneSpecs = [...droneSpecs];
    newDroneSpecs[index].value = event.target.value;
    setDroneSpecs(newDroneSpecs);
  };

  const addNewSpec = () => {
    setDroneSpecs([...droneSpecs, { spec: "", value: "" }]);
  };

  const removeSpec = (index: number) => {
    if (droneSpecs.length > 1) {
      setDroneSpecs((prevSpecs) => prevSpecs.filter((_, idx) => idx !== index));
    }
  };

  type LocationType = {
    value: string;
    label: string;
    lat?: number;
    lng?: number;
  };

  const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(
    null
  );

  const [inputValue, setInputValue] = useState("");
  const totalSteps = 10;
  const progressPerStep = 100 / totalSteps;
  const stepCompletion = progressPerStep * currentStep;

  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");

  const brandsAndModels: { [key: string]: string[] } = {
    "": [],
    DJI: [
      "Phantom 4",
      "Phantom 4 Pro",
      "Phantom 4 Advanced",
      "Mavic Pro",
      "Mavic Air",
      "Mavic Air 2",
      "Mavic 2 Pro",
      "Mavic 2 Zoom",
      "Mavic Mini",
      "Mavic Mini 2",
      "Inspire 1",
      "Inspire 2",
      "Spark",
      "FPV Drone",
      "Air 2S",
      "Mini SE",
      // ... (și multe alte modele)
    ],
    Parrot: [
      "Anafi",
      "Anafi USA",
      "Anafi Thermal",
      "Bebop 2",
      "Bebop 2 Power",
      "Disco",
      "Disco AG",
      "Bluegrass",
      "Bluegrass Fields",
      "Ar.Drone 2.0",
      // ... (și multe alte modele)
    ],
    Autel: [
      "EVO Lite",
      "EVO Lite+",
      "EVO Lite Premium",
      "EVO Nano",
      "EVO Nano+",
      "EVO II",
      "EVO II Pro",
      "EVO II Dual",
      "Dragonfish",
      // ... (și multe alte modele)
    ],
    Yuneec: [
      "Typhoon H Pro",
      "Typhoon H3",
      "Typhoon Q500 4K",
      "Mantis G",
      "Mantis Q",
      "Breeze",
      "Tornado H920",
      // ... (și multe alte modele)
    ],
    Skydio: [
      "Skydio 2",
      "Skydio X2",
      "Skydio R1",
      // ... (și multe alte modele)
    ],
    SenseFly: [
      "eBee X",
      "eBee Geo",
      "eBee TAC",
      // ... (și multe alte modele)
    ],
    // ... (și multe alte branduri și modele)
  };

  const [extraGears, setExtraGears] = useState<string[]>([""]);

  const addExtraGear = () => {
    setExtraGears([...extraGears, ""]);
  };

  const removeExtraGear = (index: number) => {
    setExtraGears(extraGears.filter((_, idx) => idx !== index));
  };

  const handleExtraGearChange = (index: number, value: string) => {
    const newExtraGears = [...extraGears];
    newExtraGears[index] = value;
    setExtraGears(newExtraGears);
  };

  const [price, setPrice] = useState(10);
  const [detailedLocation, setDetailedLocation] = useState(null);

  const increasePrice = () => {
    setPrice((prevPrice) => prevPrice + 1);
  };

  const decreasePrice = () => {
    setPrice((prevPrice) => Math.max(0, prevPrice - 1)); // Scade prețul cu 10, dar nu mai jos de 0
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setPrice(value);
    }
  };

  const handleBrandChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newBrand = event.target.value;
    setBrand(newBrand);
    setModel(""); // Resetează modelul la schimbarea brandului

    setDroneDetails((prevDetails) => ({
      ...prevDetails,
      brand: newBrand,
    }));
  };

  // Funcția pentru a converti un șir base64 într-un blob
  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const [coverPhoto, setCoverPhoto] = useState<string | null>(null);

  const handleEditingCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setDroneDetails((prevDetails) => ({
      ...prevDetails,
      editingCost: value,
    }));
  };

  const handleTravelCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setDroneDetails((prevDetails) => ({
      ...prevDetails,
      travelCostPerKm: value,
    }));
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newModel = event.target.value;
    setModel(newModel);

    setDroneDetails((prevDetails) => ({
      ...prevDetails,
      model: newModel,
    }));
  };

  useEffect(() => {
    if (currentStep === 6) {
      setDroneDetails((prevDetails) => ({
        ...prevDetails,
        hourlyRate: price,
      }));
    }
  }, [price]);

  useEffect(() => {}, [droneDetails]);

  return (
    <div className="drone-stepper-page">
      <div className="droneden-header-logo-image">
        <img src={DroneDenLogoImageWizard} alt="" />
      </div>
      {currentStep === 1 && (
        <div className="step-wizard-one">
          <h2 className="header-text-choose-category">
            Choose categories you want your drone to be featured
          </h2>
          <div className="event-cards-selector">
            {categories.map((category, index) => (
              <div
                key={index}
                className={`card-events ${
                  selectedCategories[index] ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange(index)}
              >
                <img src={category.icon} alt={`${category.label} Icon`} />
                <p>{category.label}</p>
                <input
                  type="checkbox"
                  checked={selectedCategories[index]}
                  onChange={() => handleCategoryChange(index)}
                  className="hidden-checkbox"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div className="naming-drone-step">
          <div className="naming-input-box">
            <h2 className="naming-drone-header">
              Let's name your advertisement
            </h2>
            <p>Short and specific name will give the best results</p>
            <input
              type="text"
              placeholder="Example: Performing cartography drone services"
              maxLength={150} // Setează limita de caractere la 150
              value={inputValue} // Leagă valoarea inputului de starea inputValue
              onChange={(e) => setInputValue(e.target.value)} // Actualizează starea când valoarea se schimbă
            />
            <div className="length-counter">{inputValue.length}/150</div>
          </div>
          <div className="brand-and-model-selector">
            <h2 className="naming-drone-header">
              Let's choose the brand and model
            </h2>
            <div>
              <div className="brand-and-model-boxes">
                <select
                  className="brand-selection-input"
                  value={brand}
                  onChange={handleBrandChange}
                >
                  <option value="" disabled>
                    Selectează un brand
                  </option>
                  <option value="DJI">DJI</option>
                  <option value="Parrot">Parrot</option>
                  {/* Adaugă alte opțiuni de brand aici */}
                </select>

                <select
                  value={model}
                  onChange={handleModelChange}
                  disabled={!brand}
                  className="model-selection-input"
                >
                  <option value="" disabled>
                    Selectează un model
                  </option>
                  {brandsAndModels[brand].map(
                    (modelOption: string, index: number) => (
                      <option key={index} value={modelOption}>
                        {modelOption}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div>
          <div className="user-experience-description">
            <h2>What makes you suitable for the job?</h2>
            <p>
              More details, about your experience higher chance to be discovered
            </p>
            <div className="text-area-wrapper">
              <textarea
                className="experience-textarea"
                placeholder="Describe your experience here..."
                maxLength={500}
                value={droneDetails.experience || ""} // bind it to a property in your state object
                onChange={(e) =>
                  setDroneDetails({
                    ...droneDetails,
                    experience: e.target.value,
                  })
                } // update the state on change
              />
              <div className="length-counter-description">
                {(droneDetails.experience || "").length}/500
              </div>
            </div>
          </div>
        </div>
      )}
      {currentStep === 4 && (
        <div className="drone-specs-box-step-4">
          <div className="drone-specs-step">
            <h2>Offer some drone specs</h2>
            <p>Act like a PRO by offering technical informations</p>
            {droneSpecs.map((spec, index) => (
              <div key={index} className="drone-spec-row">
                <select
                  value={spec.spec}
                  onChange={(event) => handleSpecChange(index, event)}
                  className="drone-spec-dropdown"
                >
                  <option value="" disabled>
                    Select a spec
                  </option>
                  {specsOptions.map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  value={spec.value}
                  onChange={(event) => handleSpecValueChange(index, event)}
                  className="drone-spec-input"
                  placeholder="Enter spec value"
                />
                {index < droneSpecs.length - 1 && (
                  <button
                    onClick={() => removeSpec(index)}
                    className="remove-spec-button"
                  >
                    <img src={TrashCanIcon} alt="Delete" />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {currentStep === 5 && (
        <div className="extra-gear-step">
          <h2>Add Extra Gears</h2>
          <p>Make your offer stand out by giving something more</p>
          {extraGears.map((gear, index) => (
            <div key={index} className="extra-gear-row">
              <input
                type="text"
                value={gear}
                onChange={(e) => handleExtraGearChange(index, e.target.value)}
                className="extra-gear-input"
                placeholder="Enter extra gear"
              />
              <button
                onClick={() => removeExtraGear(index)}
                className="remove-extra-gear-button"
              >
                <img src={TrashCanIcon} alt="Delete" />
              </button>
            </div>
          ))}

          <button onClick={addExtraGear} className="add-extra-gear-button">
            + Add
          </button>
        </div>
      )}
      {currentStep === 6 && (
        <div className="price-setup-page">
          <div className="price-text-header">
            <h2>Set a daily price and optional offers</h2>
            <p>
              Setting multiple price options makes your listing more
              competitive.
            </p>
          </div>
          <div className="price-setting-box">
            <button onClick={increasePrice}>+</button>
            <div className="price-wrapper">
              <span className="euro-sign">€</span>
              <input
                type="text"
                value={price}
                onChange={handleInputChange}
                className="price-input"
              />
              <span className="price-per-hour">/hour</span>
            </div>
            <button onClick={decreasePrice}>-</button>
          </div>

          <p className="similar-text">
            Simmilar products for rent range between €35 - €55
          </p>
          <div className="extra-options-box">
            <div className="photo-video-edit">
              <p>Photo/video edit costs </p>
              <span className="euro-sign2">€</span>
              <input
                className="photo-edit-input"
                type="number"
                placeholder="1500€"
                onChange={handleEditingCostChange}
              />
            </div>
            <div className="travel-costs">
              <div className="photo-video-edit">
                <p>Travel-costs/km</p>
                <span className="euro-sign3">€</span>
                <input
                  className="photo-edit-input"
                  type="number"
                  onChange={handleTravelCostChange}
                />
                <span className="kilometer-span">/km</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 7 && (
        <div className="location-settings-step">
          <div className="location-settings-box">
            <h2>Location</h2>
            <p>Choose an approximate location where you can rent the drone</p>
          </div>
          <div className="input-and-maps">
            <AsyncSelect
              className="location-input-step"
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={handleLocationChange}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                control: (provided) => ({
                  ...provided,
                  border: "1px solid #232940",
                  borderRadius: "8px",
                  fontSize: "1.125em",
                  padding: "15px 60px",
                  marginBottom: "11px",
                }),
              }}
            />
            <GoogleMapsContainer
              droneLocation={
                selectedLocation
                  ? { lat: selectedLocation.lat!, lng: selectedLocation.lng! }
                  : null
              }
              onLocationChange={(newLocation) => {
                // Obțineți numele orașului folosind serviciul de geocodare inversă de la Google Maps API
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode(
                  { location: newLocation },
                  (results, status) => {
                    if (status === "OK") {
                      if (results && results[0]) {
                        const addressComponents = results[0].address_components;
                        const cityNameComponent = addressComponents?.find(
                          (component) => component.types.includes("locality")
                        );

                        const cityName = cityNameComponent
                          ? cityNameComponent.long_name
                          : results[0].formatted_address;
                        setSelectedLocation({
                          ...selectedLocation,
                          lat: newLocation.lat,
                          lng: newLocation.lng,
                          value: cityName, // Setează valoarea cu numele locației obținut
                          label: cityName, // Setează eticheta cu numele locației obținut
                        });
                      } else {
                        window.alert("No results found");
                      }
                    } else {
                      window.alert("Geocoder failed due to: " + status);
                    }
                  }
                );
              }}
            />
          </div>
        </div>
      )}
      {currentStep === 8 && (
        <div className="image-upload-zone">
          <div className="image-upload-step-text">
            <h2>Add at least 5 photos to showcase your work</h2>
            <p>
              People react to images so make sure to use your best shots of the
              product
            </p>
          </div>
          <div
            className="image-upload-cover-photo"
            onClick={() => fileInputRef.current?.click()}
          >
            {coverPhoto ? (
              <>
                <img
                  src={coverPhoto}
                  alt="Cover Photo"
                  className="cover-photo-drone"
                />
                <div
                  className="trashcan-div"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCoverPhotoDelete();
                  }}
                >
                  <img
                    src={TrashCanIcon}
                    alt="Delete"
                    className="trashcan-icon"
                  />
                </div>
              </>
            ) : (
              <div className="image-upload-text-icon">
                <img src={EditPhotoIcon} alt="" />
                <h2>Drag photos here</h2>
                <p>or</p>
                <p className="upload-link">Upload</p>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleCoverPhotoUpload}
              style={{ display: "none" }}
            />
          </div>
          <div className="image-upload-miniature-cards">
            {images.slice(1).map(
              (
                image,
                index // folosește slice(1) pentru a exclude coverPhoto
              ) => (
                <div
                  key={index}
                  className={`image-upload-miniature ${
                    index + 1 <= (currentActiveMiniature ?? -1)
                      ? "active"
                      : "inactive"
                  }`}
                  style={{
                    backgroundImage: image ? `url(${image})` : undefined,
                  }}
                  onClick={() => coverPhoto && handleImageUpload(index + 1)} // folosește index + 1 aici
                >
                  {image && (
                    <div
                      className="trashcan-div"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageDelete(index + 1); // și aici
                      }}
                    >
                      <img
                        src={TrashCanIcon}
                        alt="Delete"
                        className="trashcan-icon"
                      />
                    </div>
                  )}
                  {!image &&
                    coverPhoto &&
                    index + 1 === currentActiveMiniature && (
                      <div>Your placeholder here</div>
                    )}
                </div>
              )
            )}
            {currentActiveMiniature !== null && currentActiveMiniature < 5 && (
              <div
                className={`image-upload-miniature ${
                  currentActiveMiniature === images.length
                    ? "active"
                    : "inactive"
                }`}
                onClick={() => coverPhoto && handleImageUpload(images.length)}
              >
                <div className="overlay-empty-miniature">+Add more</div>
              </div>
            )}
          </div>
        </div>
      )}
      {currentStep === 9 && (
        <div className="renting-time-box">
          <div className="header-text-renting-time">
            <h2>Before we finalize...when can people start renting?</h2>
            <p>
              You can use this calendar to specify the start date of your
              advertisement and indicate any dates when the item is unavailable
              for rental.
            </p>
            <div className="text-area-field">
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                className="text-area-input"
                placeholder="Enter any additional information here"
                value={extraDetails}
                onChange={(e) => setExtraDetails(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      )}

      <div className="footer-drone-creation">
        <div className="progress-bar">
          {[25, 50, 75, 100].map((limit, index) => (
            <div key={index} className="line">
              <div
                className="fill"
                style={{
                  width: `${
                    stepCompletion >= limit
                      ? 100
                      : stepCompletion > limit - 25
                      ? (stepCompletion - (limit - 25)) * 4
                      : 0
                  }%`,
                }}
              />
            </div>
          ))}
        </div>
        <div className="footer-buttons">
          <button
            className="back-btn-drone-creation"
            onClick={() => {
              if (currentStep === 1) {
                // Redirecționează înapoi pe pagina principală

                window.location.href = "/"; // Asumând că "/" este ruta către pagina principală
              } else {
                prevStep();
              }
            }}
          >
            Back
          </button>
          {currentStep === 4 && (
            <p
              className="skip-step-btn-drone-creation"
              onClick={() =>
                setCurrentStep((prevStep) => Math.min(prevStep + 1, totalSteps))
              }
            >
              Skip this step
            </p>
          )}
          {currentStep === 5 && (
            <p
              className="skip-step-btn-drone-creation"
              onClick={() =>
                setCurrentStep((prevStep) => Math.min(prevStep + 1, totalSteps))
              }
            >
              Skip this step
            </p>
          )}

          <button
            className="continue-btn-drone-creation"
            onClick={nextStep}
            disabled={currentStep === totalSteps}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default RentOutDrones;
