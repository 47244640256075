import React from "react";
import "./InputField.css";

interface InputFieldProps {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  label,
  type,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input
        className={`${id}`}
        type={type}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        required
      />
    </>
  );
};

export default InputField;
