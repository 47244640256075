import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Cookies from "js-cookie";

import HeroSection from "./homepage/herosection/herosection";
import FilterSection from "./homepage/filtersection/filter";
import CalltoAction from "./homepage/calltoaction/calltoaction";
import BrandingList from "./homepage/branding";
import AboutSection from "./homepage/about/Introduction";
import FooterSection from "./homepage/footer/footer";
import ChatBot from "./common/chatbox-app";
import ProductPage from "./productdescription/productpage/productpage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import EmailVerification from "./components/EmailVerification";
import RentOutDrones from "./RentOutDrones/RentOutDrones";
import CheckoutPage from "./checkout/checkout_page";
import EditDrones from "./EditDrones/edit_drones";
import { StepperProvider } from "./EditDrones/components/stepper/stepper_context";
import DroneMate from "./common/DroneMate/drone_mate";

import { UserContext } from "./common/accounts/context/UserContext";

function App() {
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [locationSearchQuery, setLocationSearchQuery] =
    React.useState<string>("");

  const userContext = useContext(UserContext);
  const { setIsLoggedIn } = userContext;

  useEffect(() => {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <Route path="/reset-password" component={ResetPasswordPage} />
      <Route path="/verify-email/:token" component={EmailVerification} />
      <Route path="/checkout" component={CheckoutPage} />

      <Route path="/" exact>
        <div className="homepage-container">
          <HeroSection
            setSearchQuery={setSearchQuery}
            setLocationSearchQuery={setLocationSearchQuery}
          />
          <FilterSection
            searchResults={[]}
            searchQuery={searchQuery}
            locationSearchQuery={locationSearchQuery}
          />
          <CalltoAction />
          <BrandingList />
          <AboutSection />
          <FooterSection />
          <DroneMate />
          {/* <ChatBot /> */}
        </div>
      </Route>
      <Route
        path="/product/:id"
        exact
        render={(props) => (
          <ProductPage key={props.match.params.id} {...props} />
        )}
      />
      <Route path="/rent-out-drones" exact>
        <RentOutDrones />
      </Route>
      <Route
        path="/edit-drones/:id"
        exact
        render={(props) => (
          <StepperProvider>
            <EditDrones key={props.match.params.id} {...props} />
          </StepperProvider>
        )}
      />
    </Router>
  );
}

export default App;
