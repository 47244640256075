// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drone-mate-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    bottom: 150px;
    right: 50px;
    width: 400px;
    height: 500px;
    background-color: white;
    position: fixed;
    border-radius: 20px;
    box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);
    -webkit-box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);
    -moz-box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);
}
`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/components/DroneMainBody/drone_mate_body.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,mBAAmB;IACnB,6CAA6C;IAC7C,qDAAqD;IACrD,kDAAkD;AACtD","sourcesContent":[".drone-mate-body {\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    bottom: 150px;\n    right: 50px;\n    width: 400px;\n    height: 500px;\n    background-color: white;\n    position: fixed;\n    border-radius: 20px;\n    box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);\n    -webkit-box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);\n    -moz-box-shadow: 1px 0px 13px 3px rgba(0,0,0,0.77);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
