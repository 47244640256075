import React from "react";
import { useLocation } from "react-router-dom";
import "./summary_card.css";
import moment from "moment";

type LocationState = {
  droneData: {
    imageUrls: string[];
    name: string;
    location: string;
    price: number;
  };
  selectedDateRange: {
    startDate: string;
    endDate: string;
  };
};

function calculateNumberOfDays(startDate: string, endDate: string): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDifference = end.getTime() - start.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return daysDifference + 1; // Including the start date
}

function formatDate(dateString: string, showMonthAndYear: boolean = false) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();
  return showMonthAndYear ? `${day} - ${month} ${year}` : day.toString();
}

function SummaryCard() {
  const location = useLocation<LocationState>();
  const { droneData, selectedDateRange } = location.state;
  const startDate = moment(selectedDateRange.startDate, "YYYY-MM-DD");
  const endDate = moment(selectedDateRange.endDate, "YYYY-MM-DD");
  const imageUrl = `https://staging.droneden.ro:8080/file/download?fileName=${droneData.imageUrls[0]}`;

  const numberOfDays = calculateNumberOfDays(
    startDate.toISOString(),
    endDate.toISOString()
  );

  const totalPayment = droneData.price * numberOfDays;

  const formattedStartDate = formatDate(selectedDateRange.startDate);
  const formattedEndDate = formatDate(selectedDateRange.endDate, true);

  return (
    <div className="summary-card">
      <div className="drone-preview">
        <div className="drone-image-preview">
          <img src={imageUrl} alt="drone image" />
        </div>
        <div className="drone-summary-text">
          <div className="drone-title-name">
            <h3>{droneData.name}</h3>
          </div>
          <div className="drone-location-name">{droneData.location}</div>
          <div className="drone-shooting-days">
            Shooting days: <br />
            {formattedStartDate} - {formattedEndDate}
          </div>
        </div>
      </div>
      <hr className="horizontal-separator" />
      <div className="drone-summary-pay">
        <h3>Payment Details</h3>
        <p>
          {droneData.price} euro x {numberOfDays} days = {totalPayment} euro
        </p>
      </div>
      <hr className="horizontal-separator" />
      <div className="drone-total-payment">
        <p>Total (Euro)</p>
        <p>{totalPayment}</p>
      </div>
    </div>
  );
}

export default SummaryCard;
