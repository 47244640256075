import React from "react";
import "./edit_drone_header.css";
import DroneDenHeader from "../../assets/images/droneden-header-image-logo.png";

export const EditDroneHeader: React.FC = () => {
  return (
    <header>
      <div className="edit-drone-header">
        <img src={DroneDenHeader} alt="DroneDen Header" />
      </div>
    </header>
  );
};

export default EditDroneHeader;
