// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-drone-wrapper {
    box-sizing: border-box;
    padding: 68px 281px;
}


.name-drone-input {
    width: 100%;
    padding: 22px 28px;
    border: 1px solid #C8CBD6;
    border-radius: 8px;
    margin-top: 33px;
}

.name-drone-input.selected {
    border: #232940;
}

.brand-and-model-changes {
    margin-bottom: 98px;
}

.brand-selection-change {
    width: 35%;
    font-size: 1.125em;
    padding: 20px 21px;
    margin-right: 20px;
    border: 1px solid #232940;
    border-radius: 8px;
}

.model-selection-input-change {
    width: 61%;
    font-size: 1.125em;
    padding: 20px 21px;
    border: 1px solid #232940;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/NameAdvertisement/name_drone.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".name-drone-wrapper {\n    box-sizing: border-box;\n    padding: 68px 281px;\n}\n\n\n.name-drone-input {\n    width: 100%;\n    padding: 22px 28px;\n    border: 1px solid #C8CBD6;\n    border-radius: 8px;\n    margin-top: 33px;\n}\n\n.name-drone-input.selected {\n    border: #232940;\n}\n\n.brand-and-model-changes {\n    margin-bottom: 98px;\n}\n\n.brand-selection-change {\n    width: 35%;\n    font-size: 1.125em;\n    padding: 20px 21px;\n    margin-right: 20px;\n    border: 1px solid #232940;\n    border-radius: 8px;\n}\n\n.model-selection-input-change {\n    width: 61%;\n    font-size: 1.125em;\n    padding: 20px 21px;\n    border: 1px solid #232940;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
