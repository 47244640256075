import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";

export type User = {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
};

type DroneData = {
  id: number;
  imageUrls: string[];
  name: string;
  postedBy: User;
  dateTime?: string;
  price: number;
  description: string;
  specs: { [key: string]: string };
  location: string;
  lat?: number;
  lng?: number;
  extraDetails: string;
  categories: string[];
};

export interface Message {
  id: number;
  sender: User;
  receiver: User;
  content: string;
  timestamp: string;
}

interface UserContextProps {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  droneData: DroneData | null;
  setDroneData: React.Dispatch<React.SetStateAction<DroneData | null>>;
  images: string[];
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  fetchUserDetails: (id: number) => Promise<void>;
  fetchDroneDetails: (id: string) => Promise<void>;
  saveDroneChanges: (droneData: DroneData) => Promise<void>;
  logout: () => void;
  canEdit: boolean;
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>;
  fetchImageUrls: (imageFileNames: string[]) => Promise<void>; // Adăugați această linie
  fetchChatIds: (userId: number) => Promise<void>; // Add this line
  fetchConversation: (senderId: number, receiverId: number) => Promise<void>;
  chatIds: number[]; // Adăugați această linie
  conversation: Message[];
  sendMessage: (message: Message) => Promise<void>;
  setConversation: React.Dispatch<React.SetStateAction<Message[]>>;
  userDrones: DroneData[];
  setUserDrones: React.Dispatch<React.SetStateAction<DroneData[]>>;
  fetchUserDrones: (userId: number) => Promise<void>;
}

interface UserProviderProps {
  children: ReactNode;
}

export interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const UserContext = createContext<UserContextProps>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  userData: null,
  setUserData: () => {},
  droneData: null,
  setDroneData: () => {},
  images: [],
  setImages: () => {},
  fetchUserDetails: async () => {},
  fetchDroneDetails: async () => {},
  saveDroneChanges: async () => {},
  logout: () => {},
  canEdit: false,
  setCanEdit: () => {},
  fetchImageUrls: async (imageFileNames: string[]) => {}, // Adăugați această linie
  fetchChatIds: async () => {}, // Add this line
  fetchConversation: async () => {}, // Add this line
  chatIds: [], // Adăugați această linie
  conversation: [],
  setConversation: () => {},
  sendMessage: async (message: Message) => {},
  userDrones: [],
  setUserDrones: () => {},
  fetchUserDrones: async () => {},
});

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [droneData, setDroneData] = useState<DroneData | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const [isDroneOwnedByUser, setIsDroneOwnedByUser] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [chatIds, setChatIds] = useState<number[]>([]); // State to hold chat IDs
  const [conversation, setConversation] = useState<any[]>([]);
  const [userDrones, setUserDrones] = useState<DroneData[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("jwt_token");
    const localUserData = localStorage.getItem("userData");
    if (token && localUserData) {
      setIsLoggedIn(true);
      setUserData(JSON.parse(localUserData));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  const fetchChatIds = async (userId: number) => {
    try {
      const response = await axios.get(
        `https://staging.droneden.ro:8080/api/messages/chats/${userId}`
      );
      if (response.status === 200) {
        setChatIds(response.data);
      }
    } catch (error) {
      console.error("Error fetching chat IDs:", error);
    }
  };

  const fetchConversation = async (senderId: number, receiverId: number) => {
    try {
      const response = await axios.get(
        `https://staging.droneden.ro:8080/api/messages/conversation/${senderId}/${receiverId}`
      );
      if (response.status === 200) {
        setConversation(response.data); // Actualizăm starea conversation
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  const fetchUserDetails = async (id: number) => {
    try {
      const response = await axios.get(
        `https://staging.droneden.ro:8080/api/users/${id}`
      );
      if (response.status === 200) {
        setUserData(response.data);
        const localUserData = JSON.parse(
          localStorage.getItem("userData") || "{}"
        );
        setCanEdit(response.data.id === localUserData.id);
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("There was an error fetching the user details!", error);
    }
  };

  const fetchDroneDetails = useCallback(async (id: string) => {
    console.log("Fetching drone details for id:", id);
    try {
      const response = await axios.get(
        `https://staging.droneden.ro:8080/api/drones/${id}`
      );
      if (response.status === 200) {
        setDroneData(response.data);
        const localUserData = JSON.parse(
          localStorage.getItem("userData") || "{}"
        );
        setIsDroneOwnedByUser(response.data.postedBy.id === localUserData.id);
        setCanEdit(response.data.postedBy.id === localUserData.id);
      }
    } catch (error) {
      console.error("Error fetching drone details:", error);
    }
    console.log("Finished fetching drone details for id:", id);
  }, []);

  const saveDroneChanges = async (updatedDroneData: DroneData) => {
    try {
      const response = await axios.put(
        `https://staging.droneden.ro:8080/api/drones/${updatedDroneData.id}`,
        updatedDroneData
      );
      if (response.status === 200) {
        alert("Changes saved successfully!");
      } else {
        alert("Failed to save changes.");
      }
    } catch (error) {
      console.error("Error updating drone details:", error);
      alert("An error occurred while saving changes.");
    }
  };

  // In UserContext.tsx
  const fetchImageUrls = async (imageFileNames: string[]) => {
    const imageUrls = await Promise.all(
      imageFileNames.map(async (fileName) => {
        try {
          const response = await fetch(
            `https://staging.droneden.ro:8080/file/download?fileName=${fileName}`
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        } catch (error) {
          console.error("Failed to fetch image:", error);
          return "";
        }
      })
    );
    setImages(imageUrls); // Assuming setImages is a state setter function for storing image URLs
  };

  const fetchUserDrones = async (userId: number) => {
    try {
      const response = await axios.get(`https://staging.droneden.ro:8080/api/drones`);
      if (response.status === 200) {
        const userOwnedDrones = response.data.filter(
          (drone: DroneData) => drone.postedBy.id === userId
        );
        setUserDrones(userOwnedDrones);
      }
    } catch (error) {
      console.error("Error fetching drones:", error);
    }
  };

  const sendMessage = async (message: Message) => {
    try {
      const response = await axios.post(
        `https://staging.droneden.ro:8080/api/messages`,
        {
          sender: { id: message.sender.id },
          receiver: { id: message.receiver.id },
          content: message.content,
        }
      );
      if (response.status === 201) {
        // Mesajul a fost creat cu succes.
      } else {
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("An error occurred while sending the message:", error);
    }
  };

  const logout = () => {
    console.log("userData before logout:", userData); // Adăugați această linie
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
    setUserData(null);
    console.log("userData after logout:", userData); // Adăugați această linie
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        userData,
        setUserData,
        fetchUserDetails,
        logout,
        droneData,
        setDroneData,
        images,
        setImages,
        fetchDroneDetails,
        saveDroneChanges,
        canEdit,
        setCanEdit,
        fetchChatIds,
        fetchImageUrls,
        fetchConversation,
        chatIds,
        conversation,
        sendMessage,
        setConversation,
        userDrones,
        setUserDrones,
        fetchUserDrones,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
