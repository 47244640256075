// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set-questions {
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    display: flex;
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px;
    white-space: nowrap;
    height: auto;
}

.set-questions::-webkit-scrollbar {
    width: 12px; 
}

.set-questions::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;  
}
.set-questions::-webkit-scrollbar-thumb {
    background: #232940;
    border-radius: 10px;  
}

.set-questions::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.question {

    background-color: #232940;
    margin-right: 5px;
    color: white;
    padding: 5px;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/components/DroneQuestions/drone_mate_questions.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".set-questions {\n    align-items: center;\n    border-radius: 10px;\n    box-shadow: 0 2px 4px rgba(0,0,0,.2);\n    display: flex;\n    margin-top: 20px;\n    overflow-x: auto;\n    overflow-y: hidden;\n    padding: 10px;\n    white-space: nowrap;\n    height: auto;\n}\n\n.set-questions::-webkit-scrollbar {\n    width: 12px; \n}\n\n.set-questions::-webkit-scrollbar-track {\n    background: #f1f1f1;\n    border-radius: 10px;  \n}\n.set-questions::-webkit-scrollbar-thumb {\n    background: #232940;\n    border-radius: 10px;  \n}\n\n.set-questions::-webkit-scrollbar-thumb:hover {\n    background: #555; \n}\n\n.question {\n\n    background-color: #232940;\n    margin-right: 5px;\n    color: white;\n    padding: 5px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
