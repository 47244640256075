import React from "react";
import "./Introduction.css";
import AboutSectionLogo from "./assets/images/logo-about-section.png";
import DroneMountainsImage from "./assets/images/drone-flying-mountains.png";
import DroneSunsetImage from "./assets/images/drone-flying-ocean.png";

const AboutSection: React.FC = () => {
  return (
    <div className="introduction-container">
      <div className="about-container">
        <img className="about-section-logo" src={AboutSectionLogo} alt="" />
        <p className="first-paragraph">
          DroneDen allows users to rent drones and capture stunning aerial views
          of their surroundings. With our platform, users can easily browse and
          book a variety of drones, from beginner-friendly models to high-end
          professional drones, for their personal or business needs.
        </p>
        <p className="second-paragraph">
          Our platform connects renters with trusted drone owners in their area,
          providing a hassle-free booking experience and ensuring a safe and
          enjoyable drone rental experience. Whether you’re looking to capture
          aerial photos and videos, survey land, inspect infrastructure, or just
          explore the skies, our website has something for everyone. Join us and
          take your adventures to new heights!
        </p>
        <img className="MountainDrone" src={DroneMountainsImage} alt="" />
        <img className="SunsetDrone" src={DroneSunsetImage} alt="" />
      </div>
    </div>
  );
};

export default AboutSection;
