import React, { useEffect, useState } from "react";
import axios from "axios";
import "./signup.css";
import { hashPassword } from "../hashPassword/hashPassword";

interface SignUpProps {
  isVisible: boolean;
  onClose: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ isVisible, onClose }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const hashedPassword = hashPassword(password);

    try {
      const response = await axios.post("https://staging.droneden.ro:8080/api/users", {
        firstName,
        lastName,
        email,
        password: hashedPassword,
        phoneNumber,
        role: "USER",
      });

      if (response.status === 200) {
        setSuccessMessage("Un email de verificare a fost trimis");
      }
    } catch (error: any) {
      setError("A apărut o eroare");
    }
  };

  return isVisible ? (
    <div className="modal-overlay">
      <div className="signup-modal-content">
        <button className="signup-modal-close-btn" onClick={onClose}>
          X
        </button>
        <h2>Sign Up</h2>
        <hr />
        <form className="signup-form" onSubmit={handleSubmit}>
          <label htmlFor="signup-email-input">Email:</label>
          <input
            id="signup-email-input"
            type="email"
            name="email"
            value={email}
            className="signup-input"
            onChange={(e) => setEmail(e.target.value)}
            title="Please enter a valid email address."
            required
          />
          <br />
          <label htmlFor="signup-firstname">First Name:</label>
          <input
            id="signup-firstname"
            type="text"
            name="first name input"
            value={firstName}
            className="signup-input"
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <br />
          <label htmlFor="signup-lastname">Last Name:</label>
          <input
            id="signup-lastname"
            type="text"
            name="last name input"
            value={lastName}
            className="signup-input"
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <br />
          <label htmlFor="signup-phonenumber">Phone Number:</label>
          <input
            id="signup-phonenumber"
            type="text"
            name="phonenumber"
            value={phoneNumber}
            className="signup-input"
            onChange={(e) => setPhoneNumber(e.target.value)}
            pattern="\d{10}"
            title="Please enter exactly 10 digits"
            required
          />
          <br />
          <label htmlFor="signup-password-input">Password:</label>
          <input
            id="signup-password-input"
            type="password"
            name="password"
            value={password}
            className="signup-input"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <label htmlFor="signup-confirm-pass">Confirm Password:</label>
          <input
            id="signup-confirm-pass"
            type="password"
            name="confirm_password"
            value={confirmPassword}
            className="signup-input"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <br />
          <label>
            <input
              type="checkbox"
              className="checkbox-input"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              required
            />
            I agree to the terms and conditions
          </label>
          <br />
          <button className="signup-btn" type="submit">
            Sign Up
          </button>
        </form>
        {successMessage && (
          <div className="success-message-popup">{successMessage}</div>
        )}
        {error && <div className="error-message-popup">{error}</div>}
      </div>
    </div>
  ) : null;
};

export default SignUp;
