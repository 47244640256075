// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.price-step-price-set {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.price-setting-card {
    display: flex;
    box-sizing: border-box;
    background-color: #232940;
    border-radius: 8px;
    width: 100%;
    max-width: 700px;
    padding: 35px;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 20px;
}

.price-setup {
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}


.price-tag {
    width: 50%;
    font-size: 3em;
    font-family: 'Ubuntu Bold', sans-serif;
    background: none;
    border: none;
    padding: 0;
    color: white;
}


.price-btn {
    border-radius: 50%;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #595d6f;
    border: none;
    color: white;
    font-family: 'Ubuntu Regular';
}
`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/PriceStep/price_step.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;;AAGA;IACI,UAAU;IACV,cAAc;IACd,sCAAsC;IACtC,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,6BAA6B;AACjC","sourcesContent":[".price-step-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n.price-step-price-set {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n\n.price-setting-card {\n    display: flex;\n    box-sizing: border-box;\n    background-color: #232940;\n    border-radius: 8px;\n    width: 100%;\n    max-width: 700px;\n    padding: 35px;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    margin: 0 20px;\n}\n\n.price-setup {\n    margin: 0 5%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 30%;\n}\n\n\n.price-tag {\n    width: 50%;\n    font-size: 3em;\n    font-family: 'Ubuntu Bold', sans-serif;\n    background: none;\n    border: none;\n    padding: 0;\n    color: white;\n}\n\n\n.price-btn {\n    border-radius: 50%;\n    font-size: 2em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 40px;\n    height: 40px;\n    background-color: #595d6f;\n    border: none;\n    color: white;\n    font-family: 'Ubuntu Regular';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
