export class DroneMateStore {
    messages: { from: string; text: string }[] = [];
    conversation: string[] = [];
    subscribers: Function[] = [];
  
    addMessage(from: string, text: string) {
      console.log(`Adding message from ${from}: ${text}`);
      this.messages.push({ from, text });
      this.notifySubscribers();
    }
    
  
    subscribe(fn: Function) {
      this.subscribers.push(fn);
    }
  
    notifySubscribers() {
      this.subscribers.forEach((fn) => fn());
    }
  }
  