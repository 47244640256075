import { DroneMateStore } from "./drone_mate_store";
import axios from "axios";

export class DroneMateService {
  constructor(private store: DroneMateStore) {}

  userSendMessage(text: string) {
    this.store.addMessage("user", text);

    // Apelul HTTP către backend
    axios.post("https://staging.droneden.ro:8080/api/bot", { message: text })
    .then((response) => {
      const botResponseArray: string[] = response.data;
      this.store.conversation = botResponseArray; // Aici
      
      const botMessage = botResponseArray.find((item: string) => item.startsWith("Bot: "));
      
      if (botMessage) {
        const actualBotMessage = botMessage.substring(5); // Eliminăm "Bot: " pentru a obține doar textul
        this.store.addMessage("drone", actualBotMessage); // Și aici
      }
    })
    .catch((error) => {
      console.error("There was a problem with the axios operation:", error);
    });
  }
}
