import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { formatDate } from "../../components/dateUtils";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import CalendarIcon from "./assets/images/calender-icon.png";
import moment from "moment";
import Header from "../../common/header/header";
import GoogleMapsContainer from "../../common/googlemaps/AppWithMap";
import FooterSection from "../../homepage/footer/footer";
import { UserContext } from "../../common/accounts/context/UserContext"; // asigurați-vă că calea este corectă
import "./productpage.css";
import backgroundscribble from "./assets/images/background-scribble.png";
import { Message } from "../../common/accounts/context/UserContext";
import { User } from "../../common/accounts/context/UserContext";
import { colors } from "react-select/dist/declarations/src/theme";
import { useHistory } from "react-router-dom";
import EditIcon from "./assets/images/edit-icon.png";
import HideIcon from "./assets/images/hide-icon.png";
import DeleteIcon from "./assets/images/delete-icon.png";

function ProductPage() {
  const { id } = useParams<{ id: string }>();
  const {
    droneData,
    setDroneData,
    fetchDroneDetails,
    saveDroneChanges,
    images,
    setImages,
    canEdit,
    fetchImageUrls,
    sendMessage,
    userData,
  } = useContext(UserContext);
  const history = useHistory();
  const [messageContent, setMessageContent] = useState("");
  const [isMessagePopupVisible, setIsMessagePopupVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const POSSIBLE_DRONE_SPECS = ["Weight", "Max Speed", "Battery Life"]; // Adăugați aici specificațiile pe care le considerați necesare
  const [dateRange, setDateRange] = useState("Choose your shooting days");
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isDropdownVisible, setIsDropDownVisible] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const goToEditDrones = () => {
    history.push(`/edit-drones/${id}`); // `id` este id-ul dronei pe care vrei să o editezi
  };

  const handleSaveChanges = async () => {
    if (droneData) {
      await saveDroneChanges(droneData);
    }
  };

  const toggleMessagePopup = () => {
    setIsMessagePopupVisible((prev) => !prev);
  };

  const handleMessageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (droneData && userData) {
      const sender: User = {
        id: userData.id,
        name: `${userData.firstName} ${userData.lastName}`,
      };

      const receiver: User = {
        id: droneData.postedBy.id,
        name: `${droneData.postedBy.firstName} ${droneData.postedBy.lastName}`,
      };

      const message: Message = {
        id: new Date().getTime(),
        sender,
        receiver,
        content: messageContent,
        timestamp: new Date().toISOString(),
      };

      // Trimiterea mesajului
      await sendMessage(message as any);

      // Setează mesajul ca trimis și șterge conținutul inputului
      setIsMessageSent(true);
      setMessageContent("");
    }
  };

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (isMessageSent) {
      const timer = setTimeout(() => {
        setIsMessageSent(false);
      }, 3000); // 3 secunde

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isMessageSent]);

  useEffect(() => {
    // Resetarea stării imaginilor
    setImages([]);
    // Apoi, fetch noile detalii ale dronei și imaginile
    fetchDroneDetails(id);
  }, [id, fetchDroneDetails, setImages]);

  useEffect(() => {
    if (droneData?.imageUrls && images.length === 0) {
      fetchImageUrls(droneData.imageUrls);
    }
  }, [droneData, fetchImageUrls, images]);

  if (!droneData) {
    return <div>Loading...</div>;
  }

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
  };

  const formattedDate = formatDate(droneData?.dateTime);
  return (
    <div className="product-page">
      <div className="product-page-wrapper">
        {isModalVisible && (
          <div className="modal-background" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-close" onClick={handleCloseModal}>
                X
              </button>
              <img src={selectedImage || ""} alt="Drone" />
            </div>
          </div>
        )}

        {isEditModalVisible && (
          <div
            className="edit-drone-modal-background"
            onClick={handleEditModalClose}
          >
            <div
              className="edit-drone-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="edit-drone-modal-close"
                onClick={handleEditModalClose}
              >
                ×
              </button>
              <form className="edit-drone-form">
                <label>
                  Name:
                  <input
                    type="text"
                    value={droneData.name}
                    onChange={(e) =>
                      setDroneData({ ...droneData, name: e.target.value })
                    }
                  />
                </label>
                <label>
                  Price:
                  <input
                    type="number"
                    value={droneData.price}
                    onChange={(e) =>
                      setDroneData({
                        ...droneData,
                        price: parseFloat(e.target.value),
                      })
                    }
                  />
                </label>

                {POSSIBLE_DRONE_SPECS.map((specKey) => (
                  <label key={specKey}>
                    {specKey}:
                    <input
                      type="text"
                      value={droneData?.specs?.[specKey] || ""}
                      onChange={(e) =>
                        setDroneData((prevData) => {
                          if (!prevData) return null;
                          return {
                            ...prevData,
                            specs: {
                              ...prevData.specs,
                              [specKey]: e.target.value,
                            },
                          };
                        })
                      }
                    />
                  </label>
                ))}

                <label>
                  Description:
                  <textarea
                    value={droneData.description}
                    onChange={(e) =>
                      setDroneData({
                        ...droneData,
                        description: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Availability:
                  <textarea
                    value={droneData.extraDetails || ""}
                    onChange={(e) =>
                      setDroneData({
                        ...droneData,
                        extraDetails: e.target.value,
                      })
                    }
                  />
                </label>

                <button
                  type="button"
                  className="edit-drone-save-button"
                  onClick={handleSaveChanges}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="product-page-header">
          <Header />
        </div>

        <div className="product-page-left-side">
          {/* {canEdit && ( // Adăugați această condiție
            <button className="edit-drone-details-btn" onClick={goToEditDrones}>
              Edit Drone Details
            </button>
          )} */}
          <div className="product-page-images-box">
            <div
              className="product-page-big-image"
              style={{
                backgroundImage: `url("${
                  images[0] || "https://via.placeholder.com/150"
                }")`,
              }}
              onClick={() =>
                handleImageClick(images[0] || "https://via.placeholder.com/150")
              }
            ></div>
            <div className="product-page-little-image">
              {images.slice(1).map((img, index) => (
                <div
                  className="miniature-card"
                  key={index}
                  style={{ backgroundImage: `url("${img}")` }}
                  onClick={() => handleImageClick(img)}
                ></div>
              ))}
            </div>
          </div>
          <div className="product-page-drone-specs">
            <h2 className="drone-specs-text">Drone Specs</h2>
            <div className="drone-specs-list">
              {droneData?.specs && (
                <ul>
                  {Object.entries(droneData.specs)
                    .filter(([spec, value]) => spec && value)
                    .map(([spec, value]) => (
                      <li key={spec}>
                        <strong>{spec}:</strong> {value}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div className="product-page-google-maps">
            <h2 className="google-maps-text">Location</h2>
            <GoogleMapsContainer
              droneLocation={
                droneData?.lat && droneData?.lng
                  ? { lat: droneData.lat, lng: droneData.lng }
                  : null
              }
            />
          </div>
        </div>

        <div className="product-page-right-side">
          <div className="drone-specs-box">
            <div className="name-and-edit-btn">
              <h2 className="drone-name">{droneData.name}</h2>
              {canEdit && (
                <button
                  className="drone-edit-btn"
                  onClick={() => setIsDropDownVisible(!isDropdownVisible)}
                >
                  ⋯
                </button>
              )}
              {canEdit && isDropdownVisible && (
                <div className="edit-drone-dropdown-menu">
                  <ul className="edit-drone-list">
                    <li
                      className="edit-drone-element"
                      onClick={goToEditDrones} // Acesta este handler-ul nou
                    >
                      <img
                        className="drone-edit-icon"
                        src={EditIcon}
                        alt="Edit Icon"
                      />
                      Edit Listing
                    </li>
                    <li
                      className="edit-drone-element"
                      onClick={() => {
                        /* Cod pentru acțiune */
                      }}
                    >
                      <img
                        className="drone-hide-icon"
                        src={HideIcon}
                        alt="Hide Icon"
                      />
                      Hide Listing
                    </li>
                    <hr className="edit-drone-line" />
                    <li
                      className="edit-drone-element"
                      onClick={() => {
                        /* Cod pentru acțiune */
                      }}
                    >
                      <img
                        className="drone-delete-icon"
                        src={DeleteIcon}
                        alt="Delete Icon"
                      />
                      Delete
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <p className="drone-location-date">
              {droneData.location} -{" "}
              {droneData.dateTime ? formattedDate : "Date not available"}
            </p>
            <div className="grey-rectangle-price">
              <p className="rectangle-price">
                ${droneData.price} <span>/day</span>
              </p>
            </div>
            <div className="rent-out-date">
              <div className="date-input-container">
                <button
                  className="shooting-days-btn"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {dateRange} <img src={CalendarIcon} alt="calendar icon" />
                </button>

                {isOpen && (
                  <DateRangePicker
                    startDate={selectedDateRange.startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={selectedDateRange.endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={({ startDate, endDate }) => {
                      setSelectedDateRange({ startDate, endDate });
                      if (startDate && endDate) {
                        const formattedStartDate =
                          startDate.format("DD MMMM YYYY");
                        const formattedEndDate = endDate.format("DD MMMM YYYY");
                        setDateRange(
                          `${formattedStartDate} - ${formattedEndDate}`
                        );
                        setIsOpen(false);
                      }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                  />
                )}
              </div>
              {/* <Link
                to={{
                  pathname: "/checkout",
                  state: {
                    droneData,
                    selectedDateRange: {
                      startDate: selectedDateRange.startDate
                        ? selectedDateRange.startDate.format("YYYY-MM-DD")
                        : null,
                      endDate: selectedDateRange.endDate
                        ? selectedDateRange.endDate.format("YYYY-MM-DD")
                        : null,
                    },
                  },
                }}
              >
                
              </Link> */}
              <button className="product-page-rent-out-btn">Rent out</button>
            </div>
          </div>
          <div className="product-page-description">
            <h2 className="description-header">Description</h2>
            <p className="description-text">{droneData.description}</p>
          </div>
          <div className="product-page-drone-renter-section">
            <h2 className="product-page-renter-header">Availability</h2>
            <p className="product-page-text">{droneData.extraDetails}</p>
            <h2 className="product-page-renter-about-owner">About the owner</h2>
            {isMessagePopupVisible && (
              <div className="message-popup">
                {isMessageSent && (
                  <div
                    className="message-sent-confirmation"
                    style={{ color: "white" }}
                  >
                    Message Sent Successfully!
                  </div>
                )}
                <button
                  onClick={toggleMessagePopup}
                  className="close-popup-btn"
                >
                  X
                </button>
                <p>Write a message to the renter</p>
                <form
                  onSubmit={handleMessageSubmit}
                  className="form-message-popup"
                >
                  <textarea
                    value={messageContent}
                    onChange={(e) => setMessageContent(e.target.value)}
                    placeholder="Type your message"
                    className="form-textarea-popup"
                    required
                  />
                  <button type="submit" className="send-msg-popup">
                    Send
                  </button>
                </form>
              </div>
            )}
            <div className="about-the-owner-card">
              <div className="profile-picture-holder"></div>
              <div className="owner-details">
                <h2>
                  {droneData.postedBy.firstName} {droneData.postedBy.lastName}
                </h2>
                <p className="owner-details-first-row">{droneData.location}</p>
                <p className="owner-details-second-row">Member since 2023</p>
              </div>
              <button
                className="message-owner-btn"
                onClick={toggleMessagePopup}
              >
                <img src="" alt="" />
                Message
              </button>
            </div>
          </div>
          <div className="background-image-scribble">
            <img src={backgroundscribble} alt="background scribble image" />
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
}

export default ProductPage;
