export const formatDate = (dateString?: string) => {
  if (!dateString) {
    return "No Date Provided";
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const currentDate = new Date();
  const dayDifference =
    (currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24);

  const formattedDate =
    dayDifference > 7
      ? date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : date.toLocaleString("en-US", {
          weekday: "long",
          hour: "2-digit",
          minute: "2-digit",
        });

  return formattedDate;
};
