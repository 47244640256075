// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-redirect-btn {
    box-sizing: border-box;
    margin-top: 20px;
    width: 40%;
    padding: 20px;
    background-color: #232940;
    color: white;
    border-radius: 8px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.125em;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/checkout/components/payment_redirect/payment_redirect.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".payment-redirect-btn {\n    box-sizing: border-box;\n    margin-top: 20px;\n    width: 40%;\n    padding: 20px;\n    background-color: #232940;\n    color: white;\n    border-radius: 8px;\n    font-family: 'Ubuntu', sans-serif;\n    font-size: 1.125em;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
