import React, { useState, useContext } from "react";
import dronedenlogo from "./images/DroneDen.png";
import menuicon from "./images/menu.png";
import "./header.css";
import Login from "../accounts/login/login";
import SignUp from "../accounts/signup/signup";
import AccountSettings from "../accounts/accountsettings/accountsettings";
import { UserContext } from "../accounts/context/UserContext";
import { useHistory } from "react-router-dom";

function Header() {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [isAccountSettingsModalVisible, setIsAccountSettingsModalVisible] =
    useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn = false, setIsLoggedIn } = useContext(UserContext) ?? {};
  const history = useHistory();
  const { logout } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("Account Settings");

  const handleMenuClick = (tabName: string) => {
    setIsAccountSettingsModalVisible(true);
    if (tabName === "My account") {
      setActiveTab("Account Settings"); // Aici este schimbarea
    } else {
      setActiveTab(tabName);
    }
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <div className="left-header">
          <div className="left-header">
            <img
              src={dronedenlogo}
              alt="DroneDen Logo"
              onClick={() => history.push("/")}
            />
          </div>
        </div>
        <div className="right-header">
          <div
            className="rent-out-btn"
            onClick={() => {
              if (isLoggedIn) {
                history.push("/rent-out-drones");
              } else {
                // Opțional: afișează un mesaj sau un modal care informează utilizatorul că trebuie să fie autentificat
                alert(
                  "Trebuie să fiți autentificat pentru a accesa această funcționalitate."
                );
              }
            }}
          >
            Rent your services
          </div>
          <button
            className={`menu-btn ${isMenuOpen ? "clicked" : ""}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <img src={menuicon} alt="Menu Icon" />
          </button>
          {isLoggedIn && <div className="profile-circle"></div>}{" "}
          {/* Conditionally render the profile picture */}
        </div>
      </div>
      {isMenuOpen && (
        <ul className="dropdown-menu">
          {isLoggedIn && (
            <li onClick={() => handleMenuClick("Messages")}>Messages</li>
          )}
          {isLoggedIn && <li>Orders</li>}
          {isLoggedIn && <hr />}
          {isLoggedIn && (
            <li onClick={() => handleMenuClick("My account")}>My account</li>
          )}
          <AccountSettings
            isVisible={isAccountSettingsModalVisible}
            onClose={() => setIsAccountSettingsModalVisible(false)}
            activeTab={activeTab}
            setActiveTab={setActiveTab} // Pasăm funcția și starea
          />

          {isLoggedIn && (
            <li onClick={() => handleMenuClick("My Listings")}>My Listings</li>
          )}

          {!isLoggedIn && (
            <>
              <li onClick={() => setIsSignUpModalVisible(true)}>Sign Up</li>
              <SignUp
                isVisible={isSignUpModalVisible}
                onClose={() => setIsSignUpModalVisible(false)}
              />
            </>
          )}
          {!isLoggedIn && (
            <>
              <li onClick={() => setIsLoginModalVisible(true)}>Log in</li>
              <Login
                isVisible={isLoginModalVisible}
                onClose={() => setIsLoginModalVisible(false)}
              />
            </>
          )}
          {isLoggedIn && (
            <li
              onClick={() => {
                logout(); // Apelați funcția logout definită în UserProvider
              }}
            >
              Log out
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default Header;
