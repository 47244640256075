// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-drone-header img {
    padding: 19px 74px 0 74px;
}

`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/EditDroneHeader/edit_drone_header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B","sourcesContent":[".edit-drone-header img {\n    padding: 19px 74px 0 74px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
