import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "313px",
  borderRadius: "10px",
};

const initialCenter = {
  lat: 44.4268, // Latitude of Bucharest
  lng: 26.1025, // Longitude of Bucharest
};

type AppWithMapProps = {
  droneLocation: { lat: number; lng: number } | null;
  onLocationChange?: (location: { lat: number; lng: number }) => void;
  style?: React.CSSProperties;
};

function AppWithMap({ droneLocation, onLocationChange }: AppWithMapProps) {
  const [selectedLocation, setSelectedLocation] = useState(
    droneLocation || initialCenter
  );

  useEffect(() => {
    setSelectedLocation(droneLocation || initialCenter);
  }, [droneLocation]);

  const handleMapClick = (event: google.maps.MapMouseEvent | null) => {
    if (event && event.latLng) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setSelectedLocation(newLocation);
      if (onLocationChange) {
        onLocationChange(newLocation);
      }
    }
  };

  return (
    <div className="App">
      <main>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
          id="google-maps-script"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={selectedLocation}
            zoom={10}
            onClick={handleMapClick}
          >
            <Marker
              position={selectedLocation}
              draggable={true}
              onDragEnd={(e) => {
                if (e.latLng) {
                  const newLocation = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  };
                  setSelectedLocation(newLocation);
                  if (onLocationChange) {
                    onLocationChange(newLocation);
                  }
                }
              }}
            />

            <Circle
              center={selectedLocation}
              radius={1000}
              options={{
                fillColor: "#0000FF",
                fillOpacity: 0.5,
                strokeColor: "#0000FF",
                strokeOpacity: 0.5,
                strokeWeight: 1,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </main>
    </div>
  );
}

export default AppWithMap;
