import React, { useEffect, useRef } from "react";
import "./drone_mate_msg_area.css";

interface Message {
  from: string;
  text: string;
}

interface Props {
  messages: Message[];
}

const DroneMateMsgArea: React.FC<Props> = ({ messages }) => {
  const messageEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  console.log("Messages in DroneMateMsgArea:", messages);

  return (
    <div className="msg-area-wrapper">
      {messages.map((msg: Message, index: number) => (
        <div key={index} className={`${msg.from}-msg-text`}>
          {msg.text}
        </div>
      ))}
      <div ref={messageEndRef} />
    </div>
  );
};

export default DroneMateMsgArea;
