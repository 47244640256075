// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-cards {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.category-card {
    box-sizing: border-box;
    border: 1px solid #C8CBD6;
    border-radius: 8px;
    height: 110px;
    padding: 27px 26px 0 26px;
    margin-bottom: 18px;
    margin-right: 20px;
    font-size: 0.80em;
    flex-grow: 1;
    width: 220px;
    cursor: pointer;
}


.category-card-selected {
    background-color: #F1F2F5;
    border: 1px solid #232940;
    border-radius: 8px;
}

.category-card-text {
    margin: 0;
    margin-top: 9px;
}`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/common/CategoryCards/category_card.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;;AAGA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,eAAe;AACnB","sourcesContent":[".category-cards {\n    box-sizing: border-box;\n    display: flex;\n    flex-grow: 1;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    margin-top: 70px;\n}\n\n.category-card {\n    box-sizing: border-box;\n    border: 1px solid #C8CBD6;\n    border-radius: 8px;\n    height: 110px;\n    padding: 27px 26px 0 26px;\n    margin-bottom: 18px;\n    margin-right: 20px;\n    font-size: 0.80em;\n    flex-grow: 1;\n    width: 220px;\n    cursor: pointer;\n}\n\n\n.category-card-selected {\n    background-color: #F1F2F5;\n    border: 1px solid #232940;\n    border-radius: 8px;\n}\n\n.category-card-text {\n    margin: 0;\n    margin-top: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
