// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drone-mate-footer-wrapper {
    box-sizing: border-box;
    background-color: #eee;
    margin-top: auto;
    border-radius: 0 0 20px 20px;
    height: 100%;
    max-height: 70px;
    border-top:2px solid #ddd ;
}

.drone-mate-chat-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.drone-mate-footer-input {
    width: 70%;
    margin-right: 10px;
    padding: 8px;
    border-radius: 20px;
    border: none;
}

.drone-mate-send-msg {
    font-family: 'Ubuntu Medium';
    padding: 8px 15px;
    border-radius: 8px;
    border: none;
    background-color: #232940;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/components/DroneFooter/drone_mate_footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,4BAA4B;IAC5B,YAAY;IACZ,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".drone-mate-footer-wrapper {\n    box-sizing: border-box;\n    background-color: #eee;\n    margin-top: auto;\n    border-radius: 0 0 20px 20px;\n    height: 100%;\n    max-height: 70px;\n    border-top:2px solid #ddd ;\n}\n\n.drone-mate-chat-footer {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    height: 100%;\n}\n\n.drone-mate-footer-input {\n    width: 70%;\n    margin-right: 10px;\n    padding: 8px;\n    border-radius: 20px;\n    border: none;\n}\n\n.drone-mate-send-msg {\n    font-family: 'Ubuntu Medium';\n    padding: 8px 15px;\n    border-radius: 8px;\n    border: none;\n    background-color: #232940;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
