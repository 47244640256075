import React from "react";
import "./payment_redirect.css";

function PaymentRedirect() {
  return (
    <div className="payment-redirect-section">
      <div className="payment-redirect-text">
        <p>Rules</p>
      </div>
      <div className="payment-redirect-btn-section">
        <button
          className="payment-redirect-btn"
          onClick={() =>
            (window.location.href = "https://js.stripe.com/v3/buy-button.js")
          }
        >
          Confirm and pay
        </button>
      </div>
    </div>
  );
}

export default PaymentRedirect;
