import React from "react";
import "./ground_rules.css";

function GroundRules() {
  return (
    <div className="ground-rules-section">
      <div className="ground-rules-text">
        <h3>Ground rules</h3>
        <p>To be added</p>
        <ul>
          <li>Rule1</li>
          <li>Rule2</li>
          <li>Rule3</li>
        </ul>
      </div>
    </div>
  );
}

export default GroundRules;
