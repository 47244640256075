import React, { useState, useContext, useEffect } from "react";
import "./price_step.css";
import { StepperContext } from "../stepper/stepper_context";

const PriceStep = () => {
  const context = useContext(StepperContext);
  const { droneData, setDroneData } = useContext(StepperContext)!;
  const [price, setPrice] = useState<number | null>(10);

  const increasePrice = () => {
    setPrice((prevPrice) => {
      const newPrice = prevPrice !== null ? prevPrice + 1 : prevPrice;

      // Actualizează droneData cu prețul nou
      setDroneData((prevDetails) => {
        if (!prevDetails) {
          return null;
        }
        return {
          ...prevDetails,
          price: newPrice,
        };
      });

      return newPrice;
    });
  };

  const decreasePrice = () => {
    setPrice((prevPrice) => {
      const newPrice =
        prevPrice !== null ? Math.max(0, prevPrice - 1) : prevPrice;

      // Actualizează droneData cu prețul nou
      setDroneData((prevDetails) => {
        if (!prevDetails) {
          return null;
        }
        return {
          ...prevDetails,
          price: newPrice,
        };
      });

      return newPrice;
    });
  };

  const handleEditingCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setDroneData((prevDetails) => {
      if (!prevDetails) {
        return null;
      }
      return {
        ...prevDetails,
        editingCost: value,
      };
    });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setPrice(null); // setează la null când câmpul este gol

      // Actualizează droneData cu prețul nou
      setDroneData((prevDetails) => {
        if (!prevDetails) {
          return null;
        }
        return {
          ...prevDetails,
          price: 0, // setează la 0 când câmpul este gol
        };
      });
    } else {
      const newPrice = parseInt(value, 10);
      if (!isNaN(newPrice)) {
        setPrice(newPrice);

        // Actualizează droneData cu prețul nou
        setDroneData((prevDetails) => {
          if (!prevDetails) {
            return null;
          }
          return {
            ...prevDetails,
            price: newPrice,
          };
        });
      }
    }
  };

  const handleTravelCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setDroneData((prevDetails) => {
      if (!prevDetails) {
        return null;
      }
      return {
        ...prevDetails,
        travelCostPerKm: value,
      };
    });
  };

  useEffect(() => {
    if (droneData && droneData.price) {
      setPrice(droneData.price); // Actualizează cu prețul din droneData
    }
  }, [droneData]);

  return (
    <div className="price-step-wrapper">
      <div className="price-step-top-text">
        <h2>Set a daily price and optional offers</h2>
        <p>
          Setting multiple price options makes your listings more competitive
        </p>
      </div>
      <div className="price-step-price-set">
        <div className="price-setting-card">
          <button className="price-btn" onClick={increasePrice}>
            +
          </button>
          <div className="price-setup">
            <span className="euro-sign">€</span>
            <input
              className="price-tag"
              type="number"
              placeholder="50"
              value={price !== null ? price : ""} // Dacă price este null, setează valoarea câmpului la ''
              onChange={handlePriceChange}
            />
            <span className="price-per-hour">/hour</span>
          </div>
          <button className="price-btn" onClick={decreasePrice}>
            -
          </button>
        </div>
        <p className="similar-text">
          Simmilar products for rent range between €35 - €55
        </p>
      </div>
      <div className="extra-options-box">
        <div className="photo-video-edit">
          <p>Photo/video edit costs</p>
          <span className="euro-sign2">€</span>
          <input
            className="photo-edit-input"
            type="number"
            placeholder="25"
            onChange={handleEditingCostChange}
          />
        </div>
        <div className="travel-costs">
          <p>Travel-costs/km </p>
          <span className="euro-sign3">€</span>
          <input
            className="photo-edit-input"
            type="number"
            placeholder="10"
            onChange={handleTravelCostChange}
          />
          <span className="kilometer-span">/km</span>
        </div>
      </div>
    </div>
  );
};

export default PriceStep;
