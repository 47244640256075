import React, { useContext, useState, useEffect } from "react";
import "./extra_gear.css";

import TrashCanIcon from "../../assets/images/trashcan.png";

import { StepperContext } from "../stepper/stepper_context";

const ExtraGearsStep = () => {
  const context = useContext(StepperContext);
  const { droneData, setDroneData } = useContext(StepperContext)!;
  const [extraGears, setExtraGears] = useState<string[]>([""]);

  const addExtraGear = () => {
    setExtraGears([...extraGears, ""]);
  };

  const removeExtraGear = (index: number) => {
    setExtraGears(extraGears.filter((_, idx) => idx !== index));
  };

  const handleExtraGearChange = (index: number, value: string) => {
    const newExtraGears = [...extraGears];
    newExtraGears[index] = value;
    setExtraGears(newExtraGears);

    // Actualizează droneData cu noile extraGears
    if (droneData) {
      setDroneData({
        ...droneData,
        extraGears: newExtraGears,
      });
    }
  };

  useEffect(() => {
    if (droneData && droneData.extraGears) {
      setExtraGears(droneData.extraGears);
    }
  }, [droneData]);

  if (!droneData) {
    return <div>Context is not available</div>;
  }

  return (
    <div className="extra-gear-step-wrapper">
      <div className="extra-gear-top-text">
        <h2>Extra Gear</h2>
        <p>Make your offer stand out by giving something more</p>
      </div>
      <div className="extra-gear-tabs">
        {extraGears.map((gear, index) => (
          <div key={index} className="extra-gear-row">
            <input
              type="text"
              value={gear}
              onChange={(e) => handleExtraGearChange(index, e.target.value)}
              className="extra-gear-input"
              placeholder="Enter extra gear"
            />
            <button
              onClick={() => removeExtraGear(index)}
              className="remove-extra-gear-button"
            >
              <img src={TrashCanIcon} alt="Delete" />
            </button>
          </div>
        ))}

        <button onClick={addExtraGear} className="add-extra-gear-button">
          + Add
        </button>
      </div>
    </div>
  );
};

export default ExtraGearsStep;
