import React, { useEffect, useCallback, useContext, useState } from "react";
import "./specs_step.css";
import TrashCanIcon from "../../assets/images/trashcan.png";
import { StepperContext } from "../stepper/stepper_context";

const SpecStep = () => {
  const [droneSpecs, setDroneSpecs] = useState([{ spec: "", value: "" }]);
  const { droneData, setDroneData } = useContext(StepperContext)!;

  const handleSpecChange = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newDroneSpecs = [...droneSpecs];
    newDroneSpecs[index].spec = event.target.value;
    setDroneSpecs(newDroneSpecs);
    updateDroneData(newDroneSpecs);

    // Dacă toate dropdown-urile au o valoare selectată, adaugă un nou rând
    if (newDroneSpecs.every((spec) => spec.spec)) {
      addNewSpec();
    }
  };

  const handleSpecValueChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDroneSpecs = [...droneSpecs];
    newDroneSpecs[index].value = event.target.value;
    setDroneSpecs(newDroneSpecs);
    updateDroneData(newDroneSpecs);
  };

  const addNewSpec = () => {
    setDroneSpecs([...droneSpecs, { spec: "", value: "" }]);
  };

  const removeSpec = (index: number) => {
    if (droneSpecs.length > 1) {
      setDroneSpecs((prevSpecs) => prevSpecs.filter((_, idx) => idx !== index));
    }
  };

  const updateDroneData = (newDroneSpecs: typeof droneSpecs) => {
    if (droneData) {
      const updatedDroneData = {
        ...droneData,
        specs: Object.fromEntries(
          newDroneSpecs.map(({ spec, value }) => [spec, value])
        ),
      };
      setDroneData(updatedDroneData);
    }
  };

  const specsOptions = [
    "Camera",
    "Max Flight Time",
    "Max Speed",
    "Transmission Range",
    // Adaugă alte opțiuni aici
  ];

  useEffect(() => {
    if (droneData && droneData.specs) {
      const initialSpecs = Object.keys(droneData.specs)
        .filter((key) => key) // Filtrarea cheilor goale
        .map((key) => ({
          spec: key,
          value: droneData.specs[key],
        }));
      // Adaugă "Select a spec" la sfârșitul listei
      setDroneSpecs([...initialSpecs, { spec: "", value: "" }]);
    } else {
      // Dacă nu există specuri, resetează la valoarea inițială
      setDroneSpecs([{ spec: "", value: "" }]);
    }
  }, [droneData]);

  return (
    <div className="spec-step-wrapper">
      <div className="top-side-text">
        <h2>Offer some drone specs</h2>
        <p> Act like a PRO by offering technical informations</p>
      </div>
      {droneSpecs.map((spec, index) => (
        <div key={index} className="drone-spec-edit">
          <select
            value={spec.spec}
            onChange={(event) => handleSpecChange(index, event)}
            className="drone-spec-dropdown"
          >
            {spec.spec === "" && ( // Adaugă această condiție
              <option value="" disabled>
                Select a spec
              </option>
            )}
            {specsOptions.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>

          <input
            type="text"
            value={spec.value}
            onChange={(event) => handleSpecValueChange(index, event)}
            className="drone-spec-input"
            placeholder="Enter spec value"
          />
          {index < droneSpecs.length - 1 && (
            <button
              onClick={() => removeSpec(index)}
              className="remove-spec-button"
            >
              <img src={TrashCanIcon} alt="Delete" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SpecStep;
