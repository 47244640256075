// ... alte importuri necesare
import AsyncSelect from "react-select/async";
import axios from "axios";
import React, { useState, useRef } from "react";

import "./herosection.css";
import BackgroundImage from "./assets/background.png";
import Header from "../../common/header/header";
import CloudImage from "./assets/clouds.png";
import SearchBarIcon from "./assets/search-bar-icon.png";
import MagnifyingGlassIcon from "./assets/magnifying-glass.png";

function HeroSection({
  setSearchQuery,
  setLocationSearchQuery,
}: {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setLocationSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [localSearchQuery, setLocalSearchQuery] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const droneInputRef = useRef<HTMLInputElement | null>(null);

  const loadOptions = (inputText: string, callback: Function) => {
    axios
      .get(`https://staging.droneden.ro:8080/api/maps/autocomplete?input=${inputText}`)
      .then((response) => {
        const options = response.data.predictions.map((prediction: any) => ({
          value: prediction.place_id,
          label: prediction.description,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setLocalSearchQuery(query);
    setSearchQuery(query);
    if (location) setLocationSearchQuery(location);
  };

  const handleLocationChange = (selectedOption: any) => {
    const selectedLocation = selectedOption ? selectedOption.label : "";
    setLocationSearchQuery(selectedLocation); // Actualizarea locației
  };

  const handleEraseClick = () => {
    if (droneInputRef.current) {
      droneInputRef.current.value = "";
      setLocalSearchQuery("");
      setLocationSearchQuery("");
      setSearchQuery("");
    }
  };

  return (
    <div className="hero-section">
      <Header />
      <img className="cloud-image" src={CloudImage} alt="" />
      <div className="hero-components">
        <div className="search-bar-wrapper">
          <div className="search-container">
            <img src={SearchBarIcon} alt="Search Bar" />
            <input
              type="text"
              placeholder="Drone"
              onChange={handleSearchChange}
              ref={droneInputRef}
            />
            <button className="erase-btn" onClick={handleEraseClick}>
              X
            </button>
            <span className="divider">|</span>
            <img src={SearchBarIcon} alt="" />
            <div className="search-bar">
              <div className="input-dropdown">
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  onChange={handleLocationChange}
                  onInputChange={(inputValue) => setLocation(inputValue || "")}
                  placeholder="Choose a city"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      display: "none",
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: "none",
                    }),
                  }}
                />
              </div>
            </div>
            <button className="search-btn">
              <img src={MagnifyingGlassIcon} alt="" />
              Search
            </button>
          </div>
        </div>
        <div className="hero-texts">
          <h1 className="hero-text">Explore the World from Above</h1>
          <p className="hero-text-two">
            Rent Drones and Capture Stunning Aerial Views
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
