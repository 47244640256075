import React, { useState, useEffect } from "react";
import "./drone_mate_body.css";

import DroneMateHeader from "../DroneHeader/drone_mate_header";
import DroneMateFooter from "../DroneFooter/drone_mate_footer";
import DroneMateMsgArea from "../DroneMsgArea/drone_mate_msg_area";

import { DroneMateStore } from "../../drone_mate_store";
import { DroneMateService } from "../../drone_mate_service";
import DroneMateQuestions from "../DroneQuestions/drone_mate_questions";

interface Message {
  from: string;
  text: string;
}

const store = new DroneMateStore();
const service = new DroneMateService(store);

const DroneMateBody = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    // Funcția care va actualiza mesajele
    const updateMessages = () => {
      setMessages([...store.messages]);
    };

    // Abonarea la actualizări
    store.subscribe(updateMessages);

    // Dezabonarea la demontare
    return () => {
      const index = store.subscribers.indexOf(updateMessages);
      if (index > -1) {
        store.subscribers.splice(index, 1);
      }
    };
  }, []);

  return (
    <div className="drone-mate-body">
      <DroneMateHeader />
      <DroneMateMsgArea messages={messages} />
      <DroneMateQuestions service={service} />
      <DroneMateFooter service={service} />
    </div>
  );
};

export default DroneMateBody;
