// LoginForm.js
import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import InputField from "../InputField/InputField";
import "./LoginForm.css";
import Cookies from "js-cookie";
import { hashPassword } from "../../hashPassword/hashPassword";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const userContext = useContext(UserContext);
  const { setIsLoggedIn, setUserData } = userContext;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const hashedPassword = hashPassword(password);

    try {
      const response = await axios.post(
        "https://staging.droneden.ro:8080/api/users/login",
        null,
        {
          params: {
            email,
            password: hashedPassword,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        console.log("Login successful");
        setIsLoggedIn(true);
        localStorage.setItem("jwt_token", response.data.token);

        setUserData({
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
        });

        history.push("/");
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        setError(
          "Unable to connect to the server. Please check your internet connection and try again."
        );
      } else if (error.response) {
        switch (error.response.status) {
          case 401:
            setError("Invalid email or password");
            break;
          case 500:
            setError("Server error. Please try again later.");
            break;
          default:
            setError("An unexpected error occurred");
            break;
        }
      } else {
        setError("An unexpected error occurred");
      }
    }

    setLoading(false);
  };

  return (
    <form action="" className="login-form" onSubmit={handleSubmit}>
      {error && <p className="error-message">{error}</p>}
      {loading && <p className="loading-message">Loading...</p>}
      <InputField
        id="email-input"
        label="Email"
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputField
        id="password-input"
        label="Password"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Link className="forgot-pass-link" to="/reset-password">
        Forgot Password
      </Link>
      <button className="login-continue-btn">Continue</button>
    </form>
  );
};

export default LoginForm;
