// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.introduction-container {
    background-color: #c8cbd6;
    padding: 50px 0 100px 0;
    position: relative;
    margin-top: -1%;
  }



.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 35%;
  width: 50%;
}

.about-section-logo {
  width: 35%;
}

.about-container .first-paragraph {
  margin-top: 25px;
}

.about-container .second-paragraph {
  margin-top: 30px;
}


.MountainDrone {
  position: absolute;
  top: -10%;
  left: -3%;
}

.SunsetDrone {
  position: absolute;
  top: 35%;
  left: 10%;
}


@media only screen and (max-width: 1100px) {

  .introduction-container {
    background-color: #c8cbd6;
    padding: 30px;
    position: relative;
  }

  .about-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
  }


  .MountainDrone {
    display: none;
  }

  .SunsetDrone {
    display: none;
  }
}
 





  `, "",{"version":3,"sources":["webpack://./src/homepage/about/Introduction.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;EACjB;;;;AAIF;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;;AAGA;;EAEE;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;IAEnB,WAAW;EACb;;;EAGA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".introduction-container {\n    background-color: #c8cbd6;\n    padding: 50px 0 100px 0;\n    position: relative;\n    margin-top: -1%;\n  }\n\n\n\n.about-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  margin-top: 2%;\n  margin-left: 35%;\n  width: 50%;\n}\n\n.about-section-logo {\n  width: 35%;\n}\n\n.about-container .first-paragraph {\n  margin-top: 25px;\n}\n\n.about-container .second-paragraph {\n  margin-top: 30px;\n}\n\n\n.MountainDrone {\n  position: absolute;\n  top: -10%;\n  left: -3%;\n}\n\n.SunsetDrone {\n  position: absolute;\n  top: 35%;\n  left: 10%;\n}\n\n\n@media only screen and (max-width: 1100px) {\n\n  .introduction-container {\n    background-color: #c8cbd6;\n    padding: 30px;\n    position: relative;\n  }\n\n  .about-container {\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    width: 100%;\n  }\n\n\n  .MountainDrone {\n    display: none;\n  }\n\n  .SunsetDrone {\n    display: none;\n  }\n}\n \n\n\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
