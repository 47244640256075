import React from "react";
import "./checkout_page.css";
import Header from "../common/header/header";
import SummaryCard from "./components/summary_card/summary_card";
import PaymentMethod from "./components/payment_methods/payment_method";
import CancelPolicy from "./components/cancel_policy/cancel_policy";
import GroundRules from "./components/ground_rules/ground_rules";
import PaymentRedirect from "./components/payment_redirect/payment_redirect";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51Nva4xLojgnIbI6ATpf2gI7RsSDijqVo1w5FoYqYFeQJiWPR1aMLpvtTnhjzfYkz5YdXJi6rY0f0ANJalhSGjefG00tGqoZpCc"
);

function CheckoutPage() {
  return (
    <Elements stripe={stripePromise}>
      <div className="checkout-page">
        <div className="top-header">
          <Header />
        </div>
        <hr className="horizontal-summary-separator" />
        <div className="summary-main-area">
          <div className="summary-card-component">
            <PaymentMethod />
            <hr className="horizontal-component-separator" />
            <CancelPolicy />
            <hr className="horizontal-component-separator" />
            <GroundRules />
            <hr className="horizontal-component-separator" />
            <PaymentRedirect />
          </div>
          <div>
            <SummaryCard />
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default CheckoutPage;
