// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.renting-step-wrapper {
    box-sizing: border-box;
    padding: 68px 285px;
    margin-left: 10%;
}`, "",{"version":3,"sources":["webpack://./src/EditDrones/components/RentingStep/renting_step.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".renting-step-wrapper {\n    box-sizing: border-box;\n    padding: 68px 285px;\n    margin-left: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
