// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listings-tab-wrapp {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drone-listing-tab {
    background-color: #cbcbcb;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/common/accounts/accountsettings/components/MyListingsTab/my_listings_tab.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".listings-tab-wrapp {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.drone-listing-tab {\n    background-color: #cbcbcb;\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
