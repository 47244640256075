// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-card {
    box-sizing: border-box;
    padding: 20px;
    max-width: 500px;
    border: 1px solid #CBCBCB;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-family: 'Ubuntu Regular', sans-serif;
}

.drone-preview {
    display: flex;
}

.horizontal-separator {
    border: 1px solid #CBCBCB;
    width: 100%;
}

.drone-image-preview { 
    width: 120px;
    height: 120px;
}

.drone-image-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain; 
}

.drone-summary-text {
    margin-left: 10px;
}

.drone-total-payment {
    display: flex;
    justify-content: space-between;
}

.drone-location-name {
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/checkout/components/summary_card/summary_card.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".summary-card {\n    box-sizing: border-box;\n    padding: 20px;\n    max-width: 500px;\n    border: 1px solid #CBCBCB;\n    display: flex;\n    flex-direction: column;\n    border-radius: 10px;\n    font-family: 'Ubuntu Regular', sans-serif;\n}\n\n.drone-preview {\n    display: flex;\n}\n\n.horizontal-separator {\n    border: 1px solid #CBCBCB;\n    width: 100%;\n}\n\n.drone-image-preview { \n    width: 120px;\n    height: 120px;\n}\n\n.drone-image-preview img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain; \n}\n\n.drone-summary-text {\n    margin-left: 10px;\n}\n\n.drone-total-payment {\n    display: flex;\n    justify-content: space-between;\n}\n\n.drone-location-name {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
