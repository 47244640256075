// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-area-wrapper {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}

.user-msg-text {
    font-family: 'Ubuntu Regular', sans-serif;
    background-color: #C8CBD6;
    padding: 8px;
    border-radius: 10px;
    text-align: right;
    align-self: flex-end;
    margin-bottom: 5px;
    max-width: 80%;
}

.drone-msg-text {
    font-family: 'Ubuntu Regular', sans-serif;
    background-color: #232940;
    padding: 8px;
    border-radius: 10px;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 5px;
    max-width: 80%;
    color: white;
}

.msg-area-wrapper::-webkit-scrollbar {
    width: 12px; 
}

.msg-area-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;  
}
.msg-area-wrapper::-webkit-scrollbar-thumb {
    background: #232940;
    border-radius: 10px;  
}

.msg-area-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
`, "",{"version":3,"sources":["webpack://./src/common/DroneMate/components/DroneMsgArea/drone_mate_msg_area.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".msg-area-wrapper {\n    padding: 10px;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    overflow-y: scroll;\n    height: 100%;\n}\n\n.user-msg-text {\n    font-family: 'Ubuntu Regular', sans-serif;\n    background-color: #C8CBD6;\n    padding: 8px;\n    border-radius: 10px;\n    text-align: right;\n    align-self: flex-end;\n    margin-bottom: 5px;\n    max-width: 80%;\n}\n\n.drone-msg-text {\n    font-family: 'Ubuntu Regular', sans-serif;\n    background-color: #232940;\n    padding: 8px;\n    border-radius: 10px;\n    text-align: left;\n    align-self: flex-start;\n    margin-bottom: 5px;\n    max-width: 80%;\n    color: white;\n}\n\n.msg-area-wrapper::-webkit-scrollbar {\n    width: 12px; \n}\n\n.msg-area-wrapper::-webkit-scrollbar-track {\n    background: #f1f1f1;\n    border-radius: 10px;  \n}\n.msg-area-wrapper::-webkit-scrollbar-thumb {\n    background: #232940;\n    border-radius: 10px;  \n}\n\n.msg-area-wrapper::-webkit-scrollbar-thumb:hover {\n    background: #555; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
