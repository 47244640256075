import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EmailVerification: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.get(
          `https://staging.droneden.ro:8080/register/verifyEmail?token=${token}`
        );
        setMessage("Email verified successfully. You can now log in.");
      } catch (error) {
        setMessage(
          "Email verification failed. Please try again or contact support."
        );
      }
    };

    verifyEmail();
  }, [token]);

  return <div>{message}</div>;
};

export default EmailVerification;
